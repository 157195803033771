.errorText {
    color: red;
    font-size: 12px;
  }

  
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 1px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--primary-color);
}

input:focus+.slider {
  box-shadow: var(--box-shadow);
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.enableConatiner{
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
  box-sizing: border-box;
}

.textColor {
  color: #000;
}

@media only screen and (max-width: 768px){
  .textColor {
    color: #ffffff;
  }
}