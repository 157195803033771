.menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100dvh;
  // background: var(--primary-color);
  transition: right .5s;
}

.menuActive {
  right: 0;
}
