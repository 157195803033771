.mainSection {
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  padding-top: 0;
  gap: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1.2rem;
}

.listContent {
  word-break: break-word;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.organizationError {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.pageContainer {
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  flex-grow: 1;
}

.topContainer {
  padding-top: 1rem;
  position: sticky;
  top: 0;
  background: #f8f8f8;
}

.middleContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
}

.middleSubContainer {
  display: flex;
  // flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.middleInnerContainer {
  width: 100%;
  align-self: flex-end;
}

.saveBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.animIn {
  animation-duration: 250ms;
  animation-name: slidein;
  animation-iteration-count: initial;
}

.profileNameContainer {
  min-width: 7rem;
  min-height: 7rem;
  border: var(--border);
  background: var(--secondary-color);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileName {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
}

@keyframes slidein {
  from {
    opacity: 0;
    width: 100%;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
    width: 100%;
  }
}

.profileContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.profileDetailsContainer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profileDetailsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accountName {
  font-weight: 600;
  font-size: 18px;
}

.contactInfo {
  font-size: 12px;
}

.orgCreateButton {
  max-width: 10rem;
}

.cardDetails {
  display: flex;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 1rem;
  gap: 0.5rem;
  transition: all 250ms;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cardDetails:hover {
  background: var(--highlight-color);
}

.cardContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.cardHeading {
  font-weight: bold;
  font-size: 12px;
}

.cardDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deleteContainer {
  margin-top: auto;
}

@media only screen and (max-width: 768px) {
  .middleSubContainer {
    flex-direction: column;
  }

  .topContainer {
    display: none;
  }

  .pageContainer {
    padding: 0rem;
  }

  .mainSection {
    padding: 1rem;
  }

  .cardDetails {
    justify-content: space-between;
    width: 100%;
  }
}
