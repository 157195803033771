.container {
    padding: 1rem;
}

// .draggableContainer {
//     padding: 1rem;
//     border: 1px solid gray;
//     border-radius: 5px;
//     margin-bottom: 0.5rem;
//     position: relative;
// }

// .draggableContainer * {
//     transition: all 100ms;
// }

.labelStyle {
    display: flex;
    align-items: baseline;
}

.draggableItem {
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
    position: relative;
    border-radius: var(--border-radius);
    transition: all 50ms;
    border: 1px dotted transparent;
    box-sizing: border-box;
    width: 100%;
}

.draggableItem:hover {
    background: var(--accent-color);
    border: 1px dashed #999999;
    border-radius: var(--border-radius);

    .deleteBtn {
        display: flex;
        gap: 0.5rem;
    }

    .dragBtn {
        visibility: visible;
    }
}

.dragImgContainer {
    display: flex;
    align-items: center;
}

.dragBtn {
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    visibility: hidden;
}

.dragBtn:hover,
.dragBtn:active {
    cursor: move;
}

.dragImg {
    height: fit-content;
    width: 1.5rem;
    pointer-events: none;
}

.controlContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    // overflow-x: hidden;
}

.draggedCtrl {
    box-shadow: 0px 0px 5px #cfcfcf inset;
}

.draggedCtrl * {
    opacity: 0;
}

// for section
.draggedCtrl * {
    pointer-events: none;
}

.dragOverCtrl * {
    pointer-events: none;
}

.draggedSection {
    position: relative;
    max-height: 6rem;
    overflow: hidden;
}

.draggedSection::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.6) 99%);
    width: 100%;
    height: 4rem;
}

.draggedSection .controlContainer {
    pointer-events: none;
}

.draggedSectionParent {
    position: absolute;
    top: -100vh;
    max-height: 6rem;
    overflow: hidden;
    border: 1px solid gray;
    border-radius: var(--border-radius);
}

.draggedSectionParent>.draggableItem {
    border: 0;
}

.draggedSectionParent * {
    background-color: white;
}

.sectionChildren {
    pointer-events: none;
}

.sectionChildren * {
    pointer-events: all;
}

.deleteBtn {
    position: absolute;
    right: 1.6rem;
    top: -15px;
    display: none;
}

.hoverBtn {
    background: var(--highlight-color);
    border:var(--border);
    border-radius: var(--border-radius);
    padding: 0;
    cursor: pointer;
}

.hoverBtn:hover {
    background: var(--highlight-color);
}

.contronContextBtnConatiner {
    padding: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
}

.contronContextImg {
    width: 1.2rem;
    height: 1.2rem;
}

.optionsContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
}

.optionsContainerCol {
    flex-direction: column;
    gap: 0;
}

.errorBoundary {
    border: 1px solid #FF1414;

    &:hover {
        border: 1px dashed #FF1414;
        background: #FFE5E9;
    }
}

.labelComponent {
    white-space: pre-wrap;
    word-break: break-all;
    text-wrap: balance;
    p {
        display: inline-block;
    }
}

.labelComponentQuiz {
    white-space: pre-wrap;
    display: block;
    justify-content: space-between;
    width: 100%;

    p {
        display: inline-block;
        align-items: safe;
    }
}

.labelPlaceHolder {
    color: #969696;
}

.raffleBackground {
    background: var(--accent-color);
}

.quizBackground {
    background: var(--accent-color);
}
