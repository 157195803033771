.listContainer {
  border: var(--border);
  border-radius: 4px;
  padding: 1rem;
}

.container {
  height: fit-content;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.command {
  font-weight: 600;
  color: #4a4a4a;
}

.trashContainer {
  padding: 5px 5px 6px 5px;
  border: var(--border);
  display: flex;
  border-radius: 48px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  background: var(--accent-color);

  img{
    width: 1.2rem;
  }

  &:hover {
    background: #fff;
  }
}

.container::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.container::-webkit-scrollbar-track {
  background: transparent;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}

.disableDelete {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
