$font-color: #000000;

.text{
    color: #000;
    font-weight: normal;
}

.textOne {
    @extend .text;
    font-size: 20px;
}

.textTwo {
    @extend .text;
    font-size: 18px;
}

.textThree {
    @extend .text;
    font-size: 16px;
}

.textFour {
    @extend .text;
    font-size: 14px;
}

.textFive {
    @extend .text;
    font-size: 12px;
}

.textSix {
    @extend .text;
    font-size: 11px;
}

.textSeven {
    @extend .text;
    font-size: 10px;
}

.textEight {
    @extend .text;
    font-size: 9px;
}

.textNine{
    @extend .text;
    font-size: 8px;
}

.errorText{
    color: #FF1414;
    font-size: 0.625rem;
}
