.imgEle {
  max-width: 50vw;
}

.closeBtn {
  cursor: pointer;
  text-align: end;
}

.loadingText {
  margin-top: 5vh;
}

.modal {
  display: flex;
}

.previewImgWrapper {
  min-height: 2rem;
  transition: all 2s;
}

.modalContainer {
  transition: all 2s;
}
