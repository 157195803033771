.cardDetails {
  border: var(--border);
  display: flex;
  border-radius: var(--border-radius);
  padding: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 5px #d5d5d5;
  }
}

@media screen and (max-width: 450px) {
  .cardDetails {
    width: 100%;
  }
}

.cardContainer {
  display: flex;
  gap: 1rem;
}

.cardHeading {
  font-weight: bold;
  font-size: 12px;
}

.cardDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container {
  border: var(--border);
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
}

.titleContainer {
  padding-bottom: 1rem;
}

.error {
  font-size: 12px;
  margin-top: 1rem;
  color: #ff0000;
}

.activeCard {
  border: 1px solid;
  background-color: #e9e9e9;
}

.containerErr {
  border-color: #ff0000;
}
