@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');

.btn {
  background: #EEEEEE;
  outline: none;
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  padding: 5px 12px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  transition: all 250ms ease-in;
  cursor: pointer;
  box-sizing: border-box;
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.isLoading {
  pointer-events: none;

}

.btn:hover {
  // background: var(--highlight-color);
}

.btn2 {
  @extend .btn;
  background: var(--primary-color);
  color: #FFFF;
}

.btn3 {
  @extend .btn;
  background: #EEEEEE;
}

.btn3:active{
  transform: scale(0.8);
}

.btnDisabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  background: lightgray !important;
  border: var(--border);
  border-radius: 4px;
  color: #000000 !important;
}

.btn4{
  @extend .btn;
  background: var(--secondary-color);
  color: #fff;
}

