@import "../../../styles/variables.scss";

.container {
  display: flex;
  height: 100vh;
  background: #f8f8f8;
}

.parentContainer {
  box-sizing: border-box;
  opacity: 1;
  //@TODO This will be commented out for testing purpose
  // overflow: auto;
  background: var(--background-color);
  transition: opacity .5s;
  min-height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  position: relative;
  display: flex;
  flex-direction: column;
}

.childContainer {
  width: calc(100vw - 5rem);
  min-height: 100vh;
}

.parentContainerHide {
  opacity: 0;
  display: none;
  transition: opacity;
}

@media only screen and (max-width: 768px) {
  .parentContainer {
    margin-left: 0;
    margin-top: 5rem;
    position: relative;
    min-height: calc(100dvh - 4rem);
    max-height: calc(100dvh - 4rem);
    z-index: 1;
  }

  .childContainer {
    width: 100%;
  }
}
