.topBar {
  display: flex;
  gap: 1rem;
  align-items: center;
  // justify-content: space-between;
  position: relative;
  background: var(--secondary-top-bar-color);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  .formTitle {
    font-weight: bold;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .layoutRight {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    button {
      padding: 0.5rem;
    }
  }
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.backBtnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.topBarBtn {
  width: 1rem;
  height: 1rem;
}

.publishBtn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loaderContainer {
    padding: 9px 7px;
    border-radius: 50% !important;
    // border: 1px solid #EFC7A4;
    border-radius: var(--border-radius);
    transition: border 1s ease-in-out;
    background: #fff;
    background: #c6fbc1;
}

.loaderContainerSuccess {
  // border: 1px solid #18EB04;
}

.loaderLayout {
  height: 20px;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderTextContainer {
  position: relative;
}

.loaderText1 {
  position: absolute;
  right: 0;
}

.loaderHide {
  opacity: 0;
}

.loaderShow {
  opacity: 1;
}

.loaderText2 {
  right: 0;
}

.closeModalBtn {
  background: var(--accent-color);
  border-radius: 48px;
  border: none;
  padding: 0.2rem;
  display: flex;
  box-shadow: none;
  cursor: pointer;

  img{
    width: 1.2rem;
  }

  &:active {
    transform: scale(0.9);
  }
}

.modalTitleContainer {
  display: flex;
  position: relative;
}

.modalTitleCloseBtnWrapper {
  position: absolute;
  right: 0;
}

.frame {
  width: 100%;
  height: 100%;
}

.zeroOpacity {
  opacity: 0;
}

.fullOpacity {
  opacity: 1;
}

.successTick.fullOpacity {
  animation: zoom-in-zoom-out 750ms ease-in-out;
  animation-delay: 300ms;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.8, 0.8);
  }

  50% {
    transform: scale(1, 1);
  }

  75% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.toggleContainer {
  // margin-left: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .toggleBar {
    display: flex;
    border: 1px solid #e7e7e7;
    background: var(--highlight-color);
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;
    font-weight: 600;

    .toggleBarItem {
      padding: 0.4rem 1rem;

      &.isActive {
        border-radius: var(--border-radius);
        background: var(--secondary-color);
        color: #fff;

        &:not(:last-child) {
          border-right: var(--border);
        }
      }

      // &:hover {
      //   background: #ddd8ff;
      // }
    }
  }
}

@media screen and (max-width: 1375px) {
  .toggleContainer {
    margin-left: auto;
    position: inherit;
    top: auto;
    left: auto;
    transform: none;

    .toggleBar {
      display: flex;
      border: var(--border);
      border-radius: 4px;
      margin-left: auto;
      cursor: pointer;
      font-weight: 600;

      .toggleBarItem {
        padding: 0.4rem 1rem;

        &:not(:last-child) {
          border-right: var(--border);
        }

        &.isActive {
          background: var(--secondary-color);
          color: #fff;
        }
      }
    }
  }

  .topBar {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;

    .formTitle {
      font-weight: bold;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .layoutRight {
      margin-left: 0;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      button {
        padding: 0.5rem;
      }
    }
  }
}
