@import "../../styles/variables";

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.section {
  border-radius: var(--border-radius);
  background: var(--background-color);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.parentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
