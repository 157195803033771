.actionMenuBg {
  display: none;
}

.mobileActionPanel {
  display: block;
}

@media only screen and (max-width: 768px) {
  .mobileActionPanel {
    display: none;
  }

  .tagTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actionMenuBg {
    display: block;
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .actionMenuBgActive {
    z-index: 999;
  }

  .mobileFilterContainer {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--secondary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 20rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .actionline {
    background: #AFAFAF;
    margin: 0;
    width: 100%;
    height: 0.5px;
    margin-top: 0.65rem;
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .actionButtonContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .closebtn {
    background: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .tagTitle {
    color: #fff;
  }

  .headerTab {
    width: 80%;
    overflow: auto;
  }

  .formActionContainer {
    display: none;
  }

  .isImageHolder {
    width: 1rem;
    height: 1rem;
  }
}
