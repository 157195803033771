.labelContainer {
  display: flex;
  gap: 0.5rem;
}

.table {
  display: flex;
  line-height: 0;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 11rem;

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: gray;
  }
}

.rows {
  display: flex;
  width: 100%;
  align-items: center;
}

.rows:nth-child(even) {
  background-color: #F8F8F8;
  border-radius: var(--border-radius);
}

.rows:nth-child(even).tableTitle {
  background-color: #F8F8F8;
  border-radius: var(--border-radius);
  font-weight: 600;

  .primaryCol {
    background-color: #f8f8f8;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.secondaryCol {
  width: 30%;
  padding: 0.5rem;
  font-size: 12px;
  line-height: 13px;
}

.primaryCol {
  width: 70%;
  padding: 0.5rem;
  font-size: 12px;
  position: relative;
  line-height: 13px;
  line-break: anywhere;

  &::after {
    content: '';
    border: var(--border);
    height: calc(100% - 1rem);
    right: 0;
    position: absolute;
    top: 0.5rem;
  }
}

.removeBtn {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;
}

.blacklistedEmailsTitle {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.emptyBlackListsPlaceholer {
  width: 100%;
  padding: 0.5rem;
  font-size: 12px;
  line-height: 13px;
  color: gray;
  text-align: center;
}

.removeAllBlackLists {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  padding-right: 5px;
}

.blacklistLoader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d6d6d6;
  width: 100%;
  height: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  font-size: 12px;
}
