.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: var(--secondary-top-bar-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: sticky;
    top: 0;
    z-index: 9;
    margin: 1rem 1.5rem;
}
