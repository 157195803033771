.actionContainer {
  display: flex;
  gap: 1rem;
  overflow: unset;
}

.main {
  // max-height: calc(100dvh - 8.413rem);
  // min-height: calc(100dvh - 8.413rem);
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.isImageHolder {
  width: 1.2rem;
  height: 1.2rem;
}

.loaderContainer {
  position: fixed;
  left: 50%;
  margin-left: 6rem;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
}


.loaderCube {
  position: absolute;
  left: 50%;
  top: 5rem;
  z-index: 99999;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 300ms ease-in-out, z-index 300ms;

  &.loaderClose {
    opacity: 0;
    z-index: -1;
  }
}

.contentPane {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  padding: 1rem 1.5rem;
  padding-right: 1.2rem;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;
  padding-top: 0;
  box-sizing: border-box;

  &.contentReady {
    opacity: 1;
  }
}

.headingContainer {
  display: flex;
  padding: 0.75rem 1rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  align-items: center;
}

.subHeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.topBtnContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.mobileCard {
  display: none;

}


@media only screen and (max-width: 768px) {

  .userTable {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    margin: 0;
    gap: 1rem;
  }

  .titleContainer {
    padding: 1rem 0rem;
  }

  .headRow {
    display: none;
  }

  .tableRow {
    display: none;
  }

  .mobileCardContainer {
    display: block;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    gap: 0.5rem;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #828282a9;
    position: relative;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .addBtnContainer {
    width: auto;
    position: fixed;
    bottom: 1rem;
    left: 2rem;
    right: 2rem
  }

  .mailText {
    word-wrap: break-word;
    overflow: auto;
  }
}
