.builderPane {
    flex-grow: 1;
    background: transparent;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
    display: flex;
    // gap: 1.5rem;
}

.builderPane::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

.builderPane::-webkit-scrollbar-track {
    background: transparent;
}

.builderPane::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: gray;
}

.addPageContainer {
    width: calc(100% - 13rem);
    left: 9.5rem;
    bottom: 1rem;
    position: fixed;
    display: flex;
    gap: 2rem;
}

.addPageMargin {
    width: 20%;
    min-width: 18rem;
}

.addPageBtn {
    width: fit-content;
    margin: auto;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 350ms linear;

    &:active {
        transform: scale(0.9);
    }
}

.plusBtnImg {
    width: 1rem;
    height: 1rem;
}

// .draggableContainer {
//     padding: 1rem;
//     border: 1px solid gray;
//     margin-bottom: 0.5rem;
//     position: relative;
// }

// .draggableContainer * {
//     transition: all 100ms;
// }

.titleContainer {
    z-index: 10;
    background: var(--secondary-top-bar-color);
    border: var(--border);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 0;
    position: sticky;
    top: 0;
    cursor: pointer;
}



.break {
    background: #d2d2d2;
    height: 1px;
    width: calc(100% - 2rem);
    margin: auto;
}

.formName {
    display: flex;
    justify-content: space-between;
    margin-right: 5rem;
    width: 100%;
}
