#preload {
  background-image: url(../../../../assets/REPLACE-THESE/location-marker.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
  background-image: url(../../../../assets/REPLACE-THESE/checklist.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
  background-image: url(../../../../assets/REPLACE-THESE/employee.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
  background-image: url(../../../../assets/REPLACE-THESE/office-building.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
}

.mainContainer {
  display: flex;
  background: #ffff;
  min-height: 100vh;
  position: relative;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: 0 10rem;
  margin: auto;
  gap: 1rem;
}

.formField {
  width: 100%;
  outline: none;
  border: var(--border);
  padding: 1.25rem 1rem;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  transition: all 0.2s;
  font-size: 1rem;
}

.setupFont {
  color: #707070;
}

.setupTextOne {
  @extend .setupFont;
  font-size: 25px;
  font-weight: bold;
}

.continueBtn {
  padding: 1.25rem 1rem;
  background: #3182C1;
  border-radius: var(--border-radius);
  font-size: 14px;
  width: 100%;
  color: #ffff;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.labelContainer {
  display: flex;
  background: #ffff;
  gap: 0.5rem;
  align-items: center;
}

.optionsContainer {
  display: flex;
  gap: 1rem;
}

.errorText {
  color: red;
  font-size: 10px;
  margin: 0;
  font-family: "Poppins";
}

.infoText {
  color: var(--primary-color);
  font-size: 12px;
  margin-top: 12px;
  font-weight: bold;
  font-family: "Poppins";
}

.login {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 7%;
  padding: 6px;
}

.pageLayout {
  display: contents;
}


@media only screen and (max-width: 768px) {
  .rightContainer {
    padding: 0 1rem;
    width: 100%;
  }

  .mainContainer {
    background: var(--background-color);
  }

  .pageLayout {
    display: none;
  }

  .login {
    width: 20%;
  }
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 1rem;
  }

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 3rem;
  }
}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 5rem;
  }
}
