.raffleBtnContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.raffleMain {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  width: 100%;
}

.raffleContainers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.subHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.raffleCard {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 1rem;
  gap: .3rem;
}

.rowOne {
  display: flex;
  font-size: 12px;
}

.rowTwo {
  display: flex;
  gap: 1rem;
}

.titleRow {
  display: flex;
  gap: .5rem;
}

.line {
  width: 100%;
  border: var(--border);
  position: relative;
  bottom: 0.1px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rowFour {
  display: flex;
  align-items: center;
}

.diamond {
  width: 4px;
  height: 4px;
  background: #828282;
  transform: rotate(45deg);
}

.noFormsContainer {
  background: var(--accent-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius);
  justify-content: center;
}

.raffleWinnersTitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.main {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  flex-grow: 1;
  gap: 1rem;
}

.userTableMainDiv {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: auto;
}

.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: var(--border);
}


.tableRow {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
}

.tableRow:hover {
  cursor: pointer;
  background: var(--highlight-color);
}

.headRow {
  background: var(--highlight-color);
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
  cursor: default;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  display: flex;
  font-size: 14px;
  width: 30%;
  justify-content: center;

}

.cardHolder {
  display: none;
}

.raffleEndContainer {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .userTable {
    display: none;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    font-size: 12px;
    gap: 0.25rem;
  }

  .raffleMain {
    flex-direction: column;
  }

  .raffleContainers {
    width: 100%;
  }

  .raffleEndContainer {
    width: 100%;
  }

  .subHeaderContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    align-items: flex-start;
  }

  .raffleBtnContainer {
    width: 100%;
  }

  .line {
    width: 100%;
    border: 0;
    position: relative;
    bottom: 0;
    height: 1px;
    background: #828282;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }

  .cardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}


@media only screen and (max-width: 768px) {
  .raffleCard {
    width: 100%;
  }
}

.raffleCardData {
  display: flex;
  gap: 0.25rem;
}

.raffleInEligible {
  color: red;
  font-weight: 500;
}

.raffleEligibile {
  color: green;
  font-weight: 500;
}

.eligibilityBtn {
  color: var(--secondary-color);
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}
