.container {
  flex-direction: row;
  border: var(--border);
  border-radius: 5px;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  width: 12rem;
  margin-right: 10px;
  // align-content: center;
}

.container:hover {
  background: #ECF5FF;
}

.thumbNailIcon {
  height: 3.5rem;
  max-width: 3.5rem;
}

.thumbNailImg {
  cursor: pointer;
  border-radius: 5px;
  height: 3.5rem;
  max-width: 3.5rem;
}

.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.clickable {
  cursor: pointer;
  color: #2D72AD;
}

.downloadIcon {
  height: 1rem;
  background-color: #3182C1;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
  margin-left: auto;
}

.downloading {
  width: 20px;
  background-color: #ccc;
  padding: 5px;
}

.imageWrapper {
  display: flex;
  min-width: 3.5rem;
}

.downloadBtnWrapper {
  display: flex;
  padding-right: 1rem;
  // margin-left: auto;
}

.isThumbNailLoading {
  opacity: 0.5;
}
