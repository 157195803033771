$variables: parent;

.showModal {
  background: #f8f8f8;
  box-shadow: none;
  border-radius: 2px;
  border: var(--border);
  outline: none;
  resize: none;
  // padding: 2px;
}

.editorContainer {
  margin: 4px, 4px;
  padding: 8px;
  overflow-x: hidden;
  cursor: text;
  font-size: 0.9rem;

  &:hover {
    background: var(--highlight-color);
    box-shadow: none;
  }
}

.toolTipImg {
  width: 0.8rem;
  height: 0.8rem;
}

.labelContainer {
  display: flex;
  gap: 0.5rem;
}

.propertyContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.textFormatContainer {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.formatContainer {
  display: flex;
  gap: 1rem;
}

.btn {
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  background: white;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
}

.btn:hover {
  background: var(--highlight-color);
  color:#000;
  strong{
    color: #000;
  }
}

.active {
  background: var(--secondary-color);
  color: #fff;

  strong{
    color: #fff;
  }
}

.errorEditor {
  border-color: #FF1414;
  box-shadow: var(--box-shadow);
}

.topCheckToolTip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.85);
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 4px;
    padding: 0.5rem;
    bottom: auto;
    transform: translateX(-50%) translateY(-100%);
    right: auto;
    left: 50%;
    top: 0;
    z-index: 99999;
    margin-top: -7px;
    margin-bottom: 0;
    min-width: 8rem;
    text-align: center;
  }

  &::after {
    bottom: auto;
    left: 50%;
    margin-top: -7px;
    margin-bottom: auto;
    margin-left: -10px;
    content: "";
    position: absolute;
    top: 0px;
    right: auto;
    margin-right: auto;
    z-index: 99999;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.85);
  }
}
