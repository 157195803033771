.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  width: 50%;
}

.userTableMainDiv {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: auto;
}

.quizMain {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  width: 100%;
}

.quizSubHeadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quizContainers {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: var(--border);
}

.quizWinnersTitle {
  // margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.tableRow {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
}

.tableRow:hover {
  cursor: pointer;
  background: var(--highlight-color);
}

.headRow {
  background: var(--highlight-color);
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
  cursor: default;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  display: flex;
  font-size: 14px;
  width: 30%;
  justify-content: center;

}

.cardHolder {
  display: none;
}

.endQuizContainer {
  width: 50%;
}


@media only screen and (max-width: 768px) {

  .endQuizContainer {
    width: 100%;
  }

  .quizSubHeadContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    align-items: flex-start;
  }

  .userTable {
    display: none;
  }

  .quizMain {
    flex-direction: column;
  }

  .main {
    width: 100%;
  }

  .quizContainers {
    width: 100%;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .line {
    width: 100%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }

  .cardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
