@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$sideBarWidth: 15%;
$topBarHeight: 5rem;
$primary-font: "Roboto";
$secondary-font: "Poppins";
$black: #000000;
$white: #FFFFFF;
$primary-font-color: #2F3454;
$secondary-font-color: #707070;
$success: #9FD858;
$danger: red;
$font-size: 1rem;
