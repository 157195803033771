.container {
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
}

.titleContainer {
  padding-bottom: 1rem;
}

.trash {
  cursor: pointer;
}

.containerDisabled {
  pointer-events: none;
  background-color: #f4f3f3;
}

.showTable {
  display: flex !important;
}
