.mainContainer {
  display: flex;
  background: #ffff;
  height: 100vh;
  position: relative;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: 0 10rem;
  margin: auto;
  gap: 1rem;
}

.updatedContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.login{
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: fit-content;
  padding: 6px;
}

.pageLayout{
  display: contents;
}

@media only screen and (max-width: 768px) {
  .rightContainer {
    padding: 0 1rem;
    width: 100%;
  }

  .mainContainer{
    background: var(--background-color);
   }

  .login{
    width: 20%;
  }
  .pageLayout{
    display: none;
  }
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 1rem;
  }

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 3rem;
  }
}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    padding: 0 5rem;
  }
}
