.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.userTableMainDiv {
  display: flex;
  width: 100%;
}

.actionContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.actionBtn {
  display: flex;
  gap: 1rem;
}

.backBtnContainer {
  align-self: unset;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-between;
}

.backImg {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  transform: rotate(180deg);
}

.titleText {
  font-size: 16px !important;
}

.userTableMobile {
  display: none;
}


.userTableDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
}

.headRow {
  background: var(--highlight-color);
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
  align-items: center;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
  cursor: default;
}

.tableData {
  min-width: 20%;
  max-width: 20%;
  word-wrap: break-word;
  font-size: 14px;
  padding: 0.5rem;
  max-height: 5rem;
  overflow-y: hidden;
  box-sizing: border-box;
}

.actionContainer {
  display: flex;
  gap: 1rem;
}

.responseCountContainer {
  width: 40px;
  height: 30px;
  background-color: var(--highlight-color);
  vertical-align: middle;
  border-radius: var(--border-radius);
  text-align: center;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  outline: none;
  box-shadow: var(--box-shadow);
  border: var(--border);
  font-size: 14px;
  padding: 3px;
  box-sizing: border-box;
  cursor: default;
}

.downloadSlider {
  display: none;
}

.mobileFilterContainer {
  display: none;
}

.tagTitle {
  color: #fff;
}

.tagTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileCloseBtn {
  display: none;
}

.filterHolder {
  float: left;
  margin: 3%;
  flex-wrap: wrap;
}

.downloadContainer {
  display: flex;
  width: 100%;
}

.tag {
  height: 2rem;
  width: 5rem;
  background: #F8F8F8;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  transition: all 250ms;
  cursor: pointer;
}

.tag:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.gridTable {
  display: grid;
  grid-template-columns: 1fr;
  overflow-x: auto;
  border: var(--border);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  width: 100%;
}

.tableColumn {
  display: flex;
  gap: 1rem;
  border-bottom: var(--border);
  cursor: pointer;
}

.tableColumn:hover {
  background: rgba(128, 128, 128, 0.2);
}

.tableValue {
  min-width: 15rem;
  max-width: 15rem;
  word-wrap: break-word;
  padding: 1rem;
  font-size: 14px;
}

.content {
  display: none;
}

.expand {
  display: none;
}

.expand:checked~.content {
  display: block;
}

.headingValue {
  min-width: 15rem;
  max-width: 15rem;
  word-wrap: break-word;
  padding: 1rem;
  font-size: 14px
}

.headingColumn {
  background: var(--highlight-color);
  display: flex;
  gap: 1rem;
  border-bottom: var(--border);
  font-weight: bold;
}

.gridTable::-webkit-scrollbar {
  background: transparent;
  width: 3px;
  height: 4px;
}

.gridTable::-webkit-scrollbar-track {
  background: transparent;
}

.gridTable::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}

.headCont {
  display: flex;
  gap: 1rem;
}

.downloadResp {
  display: block;
}

.cardHolder {
  display: none;
}

@media only screen and (max-width: 768px) {
  .main {
    overflow: none;
    height: 100%;
    gap: 0rem;
  }

  .backBtnContainer{
    display: none;
  }

  .gridTable {
    display: none;
  }

  .downloadResp {
    display: none;
  }

  .actionContainer {
    display: none;
  }

  .actionBtn {
    width: 100%;
  }

  .headBtn {
    width: 100%;
  }

  .headCont {
    width: 100%;
  }

  .headRow {
    background-color: white;
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
  }

  .userTableMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
  }

  .tableRowMobile {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border: var(--border);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    transition: all 500ms;
  }

  .headContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9rem;
    background-color: #D3CCFF;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    border: var(--border);

  }

  .tableData {
    width: 40%;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .tableDataMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }

  .tableDataResponseId {
    font-size: 0.8rem;
  }

  .downloadSlider {
    display: block;
    position: fixed;
    bottom: 5px;
    left: 2rem;
    right: 2rem;
  }

  .mobileFilterContainer {
    border: var(--border);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--secondary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 12rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .mobileCloseBtn {
    display: contents;
  }

  .closebtn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .line {
    width: 95%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .actionMenuBg {
    display: block;
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1
  }

  .actionMenuBgInactive {
    background: #f8f8f8;
  }

  .actionMenuBgActive {
    z-index: 0;
  }

  .cardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .rowOne {
    font-size: 12px;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .viewed {
    border: none;
    box-shadow: none;
    width: max-content;
    padding: 0;
    background: none;
    color: #FFC702;
  }

  .viewed {
    @extend .statusData;
    border: var(--border);
    box-shadow: var(--box-shadow);
  }


  .statusData {
    background: #F8F8F8;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    font-weight: 600;
    padding: 0.3rem 0.3rem;
    min-width: 50%;
    text-align: center;
  }

  .statusData {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    flex-grow: 1;
  }

  .dataContainer {
    display: flex;
    word-break: break-all;
    font-weight: 500;
  }

  .mobileViewAllContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .viewAllContainer {
    font-size: 12px;
    font-weight: 500;
    color: black;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .line {
    width: 100%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }
}
