 .search input[type="text"] {
    border: none;
    padding: 0.5rem !important;
    padding-right: 2.5rem !important;
    font-size: 14px;
    background: var(--secondary-top-bar-color);
    border: var(--border);
    box-shadow: none;
    border-radius: var(--border-radius);
    font-family: 'Poppins';
    box-sizing: border-box;
    width: 100%;
  }

  .search{
    width: 100%;
    position: relative;
  }

  .searchIcon{
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.5rem;
    right: 0.5rem;
  }

 @media only screen and (max-width:768px){
  .search input[type="text"]{
    padding-right: 8px !important;
  }

  .searchIcon{
    background: #fff;
  }
 }
