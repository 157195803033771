.hasPadding {
  margin-left: 5rem;
  padding: 2rem;
  min-height: 100vh;
}

.isImageHolder {
  width: 1rem;
  height: 1rem;
}

.hasMarginRight {
  margin-right: 0.75rem;
}

.link {
  color: #000;
}

.formValue {
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Poppins';
  word-wrap: break-word;
  overflow: hidden;
}

.tabStyles {
  display: flex;
}

.tabHeadings {
  min-width: 25%;
}

.mobilePicker {
  display: block;
  min-width: 25%;
  margin-left: auto;
}

.formLabel {
  font-family: 'Poppins';
  font-size: 1rem;
}

.actionContainerDesk {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  overflow: unset;
}


.main {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-height: 100vh;
  gap: 1rem;
  box-sizing: border-box;
  background: #f8f8f8;
}

.topContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #eeeeee;
  padding: 0.5rem;
}

.topBtnContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.linkMainContainer {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  width: 100%;
}

.linkLeftContainer {
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
  background: var(--background-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius)
}

//d
.middleContainer {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  box-sizing: border-box;
  width: 100%;
}

//d
.previewContainer {
  width: 50%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.midOne {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;

  img {
    height: fit-content;
    box-shadow: var(--box-shadow);
    border: var(--border);
    border-radius: var(--border-radius);
  }
}

.midTwo {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  .card {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border: var(--border);
    width: 30%;
    flex: 1 0 auto;
    border-radius: var(--border-radius);
    border-top: 2px solid rgb(10 96 161 / 40%);
    background: #FEFEFE;
  }

  .cardTitle {
    border-bottom: 1px solid #ececec;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
  }

  .nameCard{
    display: none;
  }

  .cardContent {
    padding: 0 2rem;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 1rem;
  }

  .cardText {
    word-wrap: break-word;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
}

.statusData {
  background: #F8F8F8;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  font-weight: 600;
  padding: 0.3rem 0.3rem;
  min-width: 50%;
  text-align: center;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardHolder {
  display: none
}

.responded {
  @extend .statusData;
  border: 1px solid #15D503;
  box-shadow: var(--box-shadow);
}


.revoked {
  @extend .statusData;
  border: var(--border);
  box-shadow: var(--box-shadow);
}


.viewed {
  @extend .statusData;
  border: 1px solid #FFC702;
  box-shadow: var(--box-shadow);
}

.sent {
  @extend .statusData;
  border: 1px solid #FF1414;
  box-shadow: var(--box-shadow);
}

.shareEmailContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mailContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.customLinkContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;
  background: var(--background-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius)
}

.frame {
  width: 100%;
  height: 100%;
}

.closeModalBtn {
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
}

.modalTitleContainer {
  display: flex;
  position: relative;
}

.modalTitleCloseBtnWrapper {
  position: absolute;
  right: 0;
}

.infoText {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins";
}

// .mobilePicker{
//   display: none;
// }

.viewResponseContainer {
  display: none;
}

.actionContainer {
  display: none;
}

.actionMenuBtn {
  display: none;
}

.actionMenuBg {
  display: none;
}

.mobileActionPanel {
  display: block;
}

.tabsLayout {
  width: 25%;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    margin-left: auto;
  }
}

.arrowUp {
  transform: rotate(180deg);
}

.arrow {
  transition: all 300ms ease-in-out;
  height: 1rem;
  width: 1.5rem;
}

.containerOne {
  width: 50%;
// padding: 1rem;
// box-sizing: border-box;
// background: #fff;
// box-shadow: var(--box-shadow);
// border-radius: var(--border-radius);
}

@media only screen and (max-width: 768px) {
  .tabsLayout {
    width: 100%;
  }

  .topBtnContainer {
    display: none;
  }

  .mobileActionPanel {
    display: none;
  }

  .middleContainer {
    box-shadow: none;
    border: none;
    overflow-x: auto;
    gap: 1rem;
    flex-direction: column;
  }

  .previewContainer {
    display: none;
  }

  .midOne {
    display: none;
  }

  .midTwo {
    width: 100%;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;

    .card {
      width: 100%;
    }

    .nameCard{
      display: flex;
    }
  }

  .midThree {
    width: 100%;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 1rem;
    min-width: 15rem;
    gap: 1rem;
  }

  .tabStyles {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .tabHeadings {
    min-width: 25%;
  }

  .mobilePicker {
    display: block;
    // min-width: 25%;
    margin-left: unset;
  }

  .responsesTable {
    display: none;
  }

  .containerOne {
    width: 100%;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    gap: 1rem;
  }

  .line {
    width: 100%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }

  .cardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .sent {
    border: none;
    box-shadow: none;
    width: max-content;
    color: #F16724;
    padding: 0;
    background: none;
  }

  .responded {
    border: none;
    box-shadow: none;
    width: max-content;
    padding: 0;
    background: none;
    color: #15D503;
  }

  .viewed {
    border: none;
    box-shadow: none;
    width: max-content;
    padding: 0;
    background: none;
    color: #FFC702;
  }

  .revoked {
    border: none;
    box-shadow: none;
    width: max-content;
    padding: 0;
    background: none;
    color: #000000;
  }

  .mobileFilterContainer {
    box-shadow: var(--box-shadow);
    border: var(--border);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--primary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 20rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .actionButtonContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .closebtn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .tagTitle {
    color: #fff;
  }

  .tagTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobileCloseBtn {
    display: contents;
  }

  .actionline {
    background: #AFAFAF;
    margin: 0;
    width: 100%;
    height: 0.5px;
    margin-top: 0.65rem;
  }

  .topContainer {
    justify-content: space-between;
  }

  .viewResponseContainer {
    display: flex;
    bottom: 3px;
    position: fixed;
    left: 2rem;
    right: 2rem;
  }

  .actionMenuBtn {
    display: block;
  }

  .actionMenuBg {
    display: block;
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    z-index: -1;
  }

  .actionMenuBgActive {
    z-index: 9;
  }

  .bottomContainer {
    margin-bottom: 2rem;
  }

  .interactionsContainer {
    max-height: 20rem;
    overflow: hidden scroll;
    padding-right: 1px;
  }

  .statusData {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    flex-grow: 1;
  }

  .linkLeftContainer {
    width: 100%;
  }

  .linkMainContainer {
    flex-direction: column;
  }

  .customLinkContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .mobileFilterContainer {
    height: 10rem;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 920px) {
  .interactionsContainer {
    // min-width: 50rem;
    max-height: 50vh;
    overflow: hidden scroll;
    padding-right: 5px;
    padding-bottom: 5px;

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 0;
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      position: relative;
      left: 2rem;
      cursor: pointer;
      width: 5px;
    }

  }

}

@media only screen and (max-width: 920px) and (min-width: 768px) {
  .statusData {
    min-width: 80%;
  }
}

.interactionsTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.raffleClosebtn {
  background: var(--accent-color);
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  outline: none;
  border: none;
  cursor: pointer;

  img{
    width: 1rem;
  }
}

.closImg {
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;

  &:active {
    transform: scale(0.9);
  }
}
