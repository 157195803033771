/* Custom properties */
:root {
  --tooltip-text-color: black;
  --tooltip-background-color: white;
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.TooltipWrapper {
  display: inline-block;
  position: relative;
  // max-width: 3rem;
}

/* Absolute positioning */
.TooltipTip {
  position: absolute;
  border-radius: var(--border-radius);
  left: 50%;
  transform: translateX(-50%);
  padding: 0.25rem 0.5rem;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 0.75rem !important;
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  outline: none;
  box-shadow: var(--box-shadow);
  border: var(--border);
  box-sizing: border-box;
}

/* CSS border triangles */
.TooltipTip::before {
  content: "";
  left: 50%;
  // border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  background: none;
  width:100%;
  // border: 1px solid #000000;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.TooltipTip.top {
  // top: calc(50px * -1);
  bottom: calc(100% + 5px);
}
/* CSS border triangles */
.TooltipTip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.TooltipTip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.TooltipTip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.TooltipTip.bottom {
  bottom: calc(var(--tooltip-margin) * 1rem);
}
/* CSS border triangles */
.TooltipTip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.TooltipTip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.TooltipTip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
