.container {
  width: 20%;
  background: var(--highlight-color);
  min-width: 18rem;
  border: var(--border);
  padding: 1rem;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  box-shadow: var(--box-shadow);
}

.break {
  width: 100%;
  height: 1px;
  background: #d5d5d5;
}
