.userMainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding: 2rem;
}

.actionContainer {
  display: flex;
  gap: 1rem;
  overflow: unset;
}

.isImageHolder {
  width: 1rem;
  height: 1rem;
}

.loaderContainer {
  position: fixed;
  left: 50%;
  margin-left: 6rem;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
}

.loaderCube {
  position: absolute;
  left: 50%;
  top: 5rem;
  z-index: 99999;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 300ms ease-in-out, z-index 300ms;

  &.loaderClose {
    opacity: 0;
    z-index: -1;
  }
}

.contentPane {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.contentReady {
    opacity: 1;
  }
}

.mobileCardContainer {
  display: none;
}

.actionPaneBlock {
  display: block;
}

.mobileActionPanel {
  display: none;
}

.titleContainer {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.subHeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.integrationMain {
  position: relative;
  padding: 1rem 1.5rem;
  padding-top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1.2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {

  .integrationMain {
    position: relative;
    padding-bottom: 7rem;
  }

  .userTable {
    display: none;
  }

  .actionPaneBlock {
    display: none;
  }

  .mobileCardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #828282a9;
    position: relative;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .addBtnContainer {
    position: fixed;
    bottom: 1rem;
    left: 1.5rem;
    right: 1.5rem;
    z-index: 99;
    background: var(--highlight-color);
    box-shadow: var(--box-shadow);
    padding: 1rem;
  }

  .mobileActionPanel {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 999;
    transform: translate3d(0, 26rem, 0);
    transition: transform .5s;
  }

  .mobileActionPanelActive {
    transform: translate3d(0, 0, 0);
    transition: transform .5s;
  }

  .actionMenuBg {
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }

  .actionMenuBgActive {
    z-index: 999;
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .mobileActionPanel {
    height: 15rem;
    left: 0%;
    overflow-y: auto;
  }
}
