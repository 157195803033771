.container {
  height: calc(100vh - 5rem);
  padding: 1rem 1.5rem;
  background: var(--background-color);
}

.title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.inputFieldContainer {
  margin-top: 2rem !important;
  position: relative;
}

.inputFieldLabel {
  position: absolute;
  top: -0.5rem;
  padding: 0 0.5rem;
  background: #FFFFFF;
  left: 0.5rem;
}

.checkBox {
  margin-right: 0.75rem;
}

.textAreaInput {
  box-sizing: border-box;
  width: 100%;
  resize: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  font-size: 1rem;
  border: var(--border);
}

.textAreaInput:focus-visible {
  outline: 0;
}

.textInput {
  box-sizing: border-box;
  width: 100%;
  resize: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  font-size: 1rem;
  outline: 0;
  border: var(--border);
}

.contentBox {
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.contentMargin {
  margin: 1.5rem 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.buttonContainer>* {
  margin-left: 2rem;
}

.errorTextArea {
  border-color: red;
}

.errorText {
  color: red;
  font-size: 13px;
}

.topPadding {
  padding-top: 0.5rem;
}

// checkbox
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 2px;
}

.checkBoxContainer .checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 1px;
  height: 1rem;
  width: 1rem;
  background-color: #F6F7FC;
  outline: 1px solid black;
  border-radius: var(--border-radius);
}

.checkBoxContainer:hover .checkbox~.checkmark {
  background-color: #ccc;
}

.checkBoxContainer .checkbox:checked~.checkmark {
  background-color: black;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkBoxContainer .checkbox:checked~.checkmark:after {
  display: block;
}

.checkBoxContainer .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: var(--border);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}


@media screen and (max-width:768px) {
  .container {
    padding: 1rem;
  }
}
