.noFormsContainer {
    background: var(--accent-color);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 5rem;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border-radius: var(--border-radius);
}
