@import "./variables";
@import "./utils.scss";
@import "bulma/bulma.sass";
@import 'bulma/css/bulma.min.css';
@import 'bulma-calendar/dist/css/bulma-calendar.min.css';
@import url('../components/v2/common/custom-calendar/custom-calendar.module.scss');

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  pointer-events: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $secondary-font;
  line-height: unset;
}

*,
*::before,
*::after {
  box-sizing: revert;
}

html {
  overflow-y: hidden;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: var(--border-radius);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#root {
  position: relative;
  color: #000;
}

input[type="date"],
input[type="text"],
input[type="search"],
input[type="time"],
input[type="file"],
input[type="number"] {
  outline: none;
}

input[type="datetime-local"] {
  padding: 2px 8px;
  outline: none;
}

:root {
  --hover-color: #2F3454;
  --scroll-bar: #2F3454;
  --box-shadow: 0px 4px 6px 0px rgba(178, 174, 174, 0.25), 0px -4px 4px 0px rgba(233, 233, 233, 0.25);
  --border: 1px solid #e7e7e7;
  --primary-color: #FFFFFF;
  --accent-color: #DEF2FF;
  --secondary-color: #0a60a1;
  --background-color: #f8f8f8;
  --highlight-color: #fff;
  --border-radius: 4px;
  --secondary-top-bar-color: #ffffff;
  --heading-font-size: 16px;
  --content-font-size: 14px;
  --info-font-size: 12px;
  --font-color: #000;
}

.is-heading {
  font-size: $font-size;
  font-weight: bold;
}

.is-sub-heading {
  font-size: $font-size;
  font-family: $secondary-font;
  font-weight: bold;
  color: $secondary-font-color;
}

.is-content {
  font-size: $font-size;
}

.is-sub-content {
  font-size: 0.9rem;
}

.is-highlighted {
  font-weight: bold;
}

.is-white {
  color: $white;
}

.is-black {
  color: $black;
}

.is-primary-color {
  color: $primary-font-color;
}

.is-secondary-color {
  color: $secondary-font-color;
}

.is-primary-font {
  font-family: $primary-font;
}

.is-secondary-font {
  font-family: $secondary-font;
}

.is-icon {
  height: 1.1rem;
}

.parent-container {
  padding: 2rem;
}

.is-required {
  color: #FF6363;
}

//Table css
.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: var(--border);
}

.tableNoShadow {
  box-shadow: none;
}

.tableRow {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border-bottom: var(--border);
  border-left: 0;
  border-right: 0;
  background: var(--highlight-color);
  transition: all 250ms;

  &:last-child {
    border-bottom: none;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
  }
}

.isClickable {
  &:hover {
    cursor: pointer;
    background: rgba(128, 128, 128, 0.2);
  }
}

.tableHeaderRow {
  @extend .tableRow;
  font-weight: bold;
  background: var(--highlight-color);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.tableData {
  flex: 1 100%;
  word-wrap: break-word;
  font-size: 14px;
  padding: 0.75rem;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobileContainer {
  display: none;
}

@media only screen and (max-width: 768px) {

  //mobile table card css
  .tableWrapper {
    display: none;
  }

  .mobileContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .line {
    width: 100%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }

  .mobileViewAllContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    color: var(--font-color);
  }
}
