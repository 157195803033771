.customFileInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.fileContainer {
    background: #fff;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 10px 15px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    transition: all 250ms ease-in;

    display: grid;
    align-items: center;
}

.fileContainer:focus {
   box-shadow: var(--box-shadow);
}

.fileContainer input {
    display: none;
}

label {
    cursor: pointer;
}

.files {
    border: var(--border);
    padding: 5px;
    border-radius: var(--border-radius);
    color: #ffff;
    background: var(--primary-color);
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.filesConatiner {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.main {
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.placeholderColor {
    padding-left: 0.1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #9a9a9a;
}

.deleteFileBubble {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.resetBtn {
    align-self: center;
    width: 2rem;
    height: 2rem;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

.readonly * {
    pointer-events: none;
}
