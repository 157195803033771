.tooltipContainer {
  display: flex;
  background: white;
  align-items: center;
  padding: 5px 9px;
  box-sizing: border-box;
  min-width: 10rem;
  max-width: 10rem;
  word-break: break-word;
  gap: 0.5rem;
  max-height: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border: var(--border);
  border-radius: var(--border-radius);
}