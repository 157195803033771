.sidepaneMain {
  background: #ffffff;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closebtn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: none !important;
}

.topOne {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topTwo {
  justify-content: space-between;
  display: flex;
}

.topThree {
  display: flex;
  justify-content: space-between;
}

.mainLabel {
  width: 25%;
}

.secondLabel {
  width: 15%;
}

.permissionContainer {
  background: #F8F8F8;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.defaultContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inpuContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkBoxContainer {
  margin: auto;
  width: fit-content;
}

.groupTitle {
  text-transform: capitalize;
}

.groupItem {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.gridList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.spacer {
  height: 1px;
  width: 100%;
  border-color: #e7e7e7;
  border-style: solid;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mobileCloseBtn {
  display: none;
}

.saveBtn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sidepaneMain {
    padding: 1rem;
    background: var(--secondary-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    max-height: 35rem;
    overflow-y: auto;
  }

  .topContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #f8f8f8;
    position: relative;
  }

  .textColor {
    color: #ffffff;
  }

  .infoText {
    color: #fff;
  }

  .mobileCloseBtn {
    display: block;
  }

  .closebtn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .mobilePaneCloseBtn {
    background: #ffffff;
    border-radius: 48px;
    border: none;
    padding: 0.2rem;
    display: flex;
    box-shadow: none;
    cursor: pointer;
  }

  .closeBtnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .saveBtn {
    display: none;
  }
}
