.container {
    padding: 1rem;
}

// .draggableContainer {
//     padding: 1rem;
//     border: 1px solid gray;
//     border-radius: 5px;
//     margin-bottom: 0.5rem;
//     position: relative;
// }

// .draggableContainer * {
//     transition: all 100ms;
// }

.dragInitiator {
    height: 2.5rem;
    width: 100%;
}

.draggableItem {
    // display: flex;
    padding: 0;
    // gap: 1rem;
    border: var(--border);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: all 50ms;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.dragImgContainer {
    display: flex;
    align-items: center;
}

.dragBtn {
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    visibility: hidden;
}

.dragBtn:hover,
.dragBtn:active {
    cursor: move;
}

.dragImg {
    height: fit-content;
    width: 1.5rem;
    pointer-events: none;
}

.controlContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    position: relative;
}

.draggedCtrl {
    box-shadow: 0px 0px 5px #cfcfcf inset;
}

.draggedCtrl * {
    opacity: 0;
}

// for section
.draggedCtrl * {
    pointer-events: none;
}

.dragOverCtrl * {
    pointer-events: none;
}

.draggedSection {
    position: relative;
    max-height: 6rem;
    overflow: hidden;
}

.draggedSection::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.6) 99%);
    width: 100%;
    height: 4rem;
    opacity: 0;
}

.draggedSection .controlContainer {
    pointer-events: none;
}

.draggedSectionParent {
    position: absolute;
    top: -100vh;
    max-height: 6rem;
    overflow: hidden;
    border: var(--border);
    border-radius: var(--border-radius);
    pointer-events: none;
    z-index: 1;
}

.draggedSectionParent>.draggableItem {
    border: 0;
}

.draggedSectionParent * {
    background-color: white;
    opacity: 0;
}

.sectionChildren {
    pointer-events: none;
}

.sectionChildren * {
    pointer-events: all;
}

.wrapControl {
    position: relative;
}

.hideElement {
    display: none;
}

.addContainer {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    border: 1px solid black;
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 99;
    pointer-events: none;
}

.addBtn {
    margin: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    pointer-events: all;
}

.addBtn:hover {
    background: red;
}

.emptySection {
    min-height: 12rem;
    border:var(--border);
    border-radius: var(--border-radius);
}

.contextMenuContainer {
    position: absolute;
    right: 1.6rem;
    top: -15px;
    display: none;
}

.draggableItem:hover {
    background: var(--accent-color);
    border: 1px dashed #999999;
    border-radius: var(--border-radius);
    box-shadow: none;

    .contextMenuContainer {
        display: flex;
        gap: 0.5rem;
    }

    .dragBtn {
        visibility: visible;
    }
}

.hoverBtn {
    background: #FFFFFF;
    border:var(--border);
    border-radius: var(--border-radius);
    padding: 0;
    cursor: pointer;
}

.hoverBtn:hover {
    background: var(--highlight-color);
}

.contronContextBtnConatiner {
    padding: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
}

.contronContextImg {
    width: 1.2rem;
    height: 1.2rem;
}

.emptySection {
    position: relative;
    // border: 1px solid #FF1414;
    // background: rgba(255, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionValidationErr {
    border: 1px solid #FF1414;
    background: rgba(255, 0, 0, 0.1);
    box-shadow: 0 2px 0 0 #FF1414;

    &:hover {
        border: 1px dashed #FF1414;
        background: rgba(255, 0, 0, 0.1);
        box-shadow: 0 2px 0 0 #FF1414;
    }

    .emptySection {
        border: 1px solid #FF1414;
        background: rgba(255, 0, 0, 0.1);
    }
}

.addPlaceholderDiv {
    width: 100%;
    height: 7.25rem;
    background: white;
    border-radius: var(--border-radius);
    border: var(--border);
    pointer-events: none;
}

.halfOpacity {
    opacity: 0.5;
}
