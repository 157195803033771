.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: var(--border-radius);
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background:#fff;
  box-shadow: var(--box-shadow);
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.allResponsesContainer {
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
}

.gapIconResponseDetails {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  align-items: center;
  background: var(--highlight-color);
  box-sizing: border-box;
  padding: 0.5rem;
  border: var(--border);
  border-radius: var(--border-radius);
  justify-content: center;
  width: 22%;
}

.iconColumn {
  display: flex;
  // flex-direction: column;
  gap: 1rem;
}

.responseText {
  font-size: 12px;
  font-weight: 600;
}

.responsesHeader {
  height: 4rem;
  display: flex;
  flex-direction: row;
  border-bottom: 4px solid black;
  gap: 6rem;
  justify-content: center;
  padding-top: 2rem;
  background-color: #D3CCFF;
}

.responseFirstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.responseSecondRow {
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
  gap: 46.8vw;
  width: 100%;
}


.lineStyle {
  margin: 0;
  background: #E8E8E8;
  display: none;
}


.responsesContainer {
  margin-top: 10px;
}

.responsesCont {
  margin-left: 4rem;
  gap: 1rem;
}

.flexRowContainer {
  display: flex;
  margin: 1.4rem;
  align-items: self-start;
}

.iconColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.responsesTextLabel {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.formSection {
  max-height: calc(100vh - 23.3rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.5rem;
}


.formSection::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}

.formSection::-webkit-scrollbar-track {
  background: transparent;
}

.formSection::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}


.formQuestionAnswerRow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  &:hover {
    background: #f8f8f8;
  }
}

.answer {
  word-break: break-all;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: default;
}

.answerImage {
  padding-left: 0.3rem;
  width: 1rem;
}



.tableRow:hover {
  background: var(--highlight-color);
  cursor: pointer
}

.question {
  word-break: break-all;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: default;
}

.clock {
  // width: 20px;
  opacity: 1;
}

.clockCont {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 0.3rem;
  width: fit-content;
  padding: 0.2rem;
  border-radius: var(--border-radius);
  border: var(--border);
  background: var(--highlight-color);
}

.responseContentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}


.bottomContianer {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  height: 100%;
}

.responsesContainer {
  height: 4rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  gap: 5rem;
  justify-content: center;
  padding-top: 2rem;
}

.responseCountContainer {
  width: 40px;
  height: 30px;
  background-color: var(--highlight-color);
  border-radius: var(--border-radius);
  text-align: center;
  font-weight: 500;
  box-shadow: var(--box-shadow);
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  font-size: 14px;
  padding: 3px;
  box-sizing: border-box;
  cursor: default;
}

.responsesSelectionColumn {
  display: flex;
  flex-direction: column;
}

.leftPane {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-sizing: border-box;
}

.downloadBtnPerRes {
  background: #3182C1;
  position: absolute;
  right: 2.5%;
  border-radius: var(--border-radius);
  border: 0;
  outline: 0;
  padding: 0.5rem 0.65rem;
  cursor: pointer;
}

.downloadBtnPerResImg {
  height: 1.25rem;
}

.fixedPagination {
  padding-top: 1rem;
  height: 14.25%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  user-select: none;
  gap: 1rem;
  margin-right: 1rem;
}

.downloadIcon {
  margin-top: 2rem;
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.downloadIconResp {
  display: flex;
  justify-content: flex-end;
}


.dropdown .dropdownBtn .downloadIcon {
  height: 13px;
  width: 13px;
  margin-left: 18px;
  margin-top: 10px;
  margin-right: 10px;
}

.dropdown .dropdownBtn .downloadText {
  padding-top: 6px;
  padding-left: 5px;
  font-family: 'Roboto';
  font-weight: 'bold';
}

.secondContainer {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.downloadAsContainer {
  width: 10rem;
}


.actionContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.actionBtn {
  display: flex;
  gap: 1rem;
}

.backBtnContainer {
  align-self: unset;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  box-sizing: border-box;
}

.backImg {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  transform: rotate(180deg);
}

.titleText {
  font-size: 16px !important;
}

.dropdown .dropdownBtn {
  outline: none;
  box-shadow: var(--box-shadow);
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  border-radius: var(--border-radius);
  transition: all 250ms ease-in;
  display: flex;
  height: 34px;
  width: 140px;
  background: #D3CCFF;
  transition-duration: 0.25s;
  color: #fff;
  cursor: pointer;
}


.dropdown .dropdownContent {
  z-index: 100;
  position: absolute;
  top: 120%;
  left: 10;
  padding: 10px;
  font-family: 'Roboto';
  background-color: #fff;
  box-shadow: var(--box-shadow);
  color: #707070;
  width: 82%;
}


.dropdown .dropdownContent .dropdownItem {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'Roboto';
  color: #707070;
}


.dropdown .dropdownContent .dropdownItem:hover {
  background: #D3CCFF;
}

.dropdown .dropdownBtn:hover {
  box-shadow: var(--box-shadow);
}

.leftScroll {
  display: none;
  flex-direction: column;
  flex-grow: 1;
  border-radius: var(--border-radius);
  box-sizing: border-box;
}


.paginationContainer {
  margin-top: 10px;
  width: 100%;
}

.paginationItems {
  gap: 1rem;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
}

.userTable {
  display: none;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: var(--border);
  flex-grow: 1;
}

.tableRow {
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  transition: all 500ms;
  justify-content: space-between;
  cursor: default;
  height: 4rem;
}

.tableRow:hover {
  background: var(--highlight-color);
}

.activeTab {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  transition: all 500ms;
  justify-content: space-evenly;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  align-items: center;
  background-color: var(--highlight-color);
  cursor: default;
  height: 4rem;
}


.headRow {
  justify-content: space-evenly;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
  font-weight: bold;
  background: #D3CCFF;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  flex-grow: 1;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.actionContainer {
  display: flex;
  gap: 1rem;
}

.responseMobileFirstRow {
  display: none;
}

.responseMobileSecondRow {
  display: none;
}

.singleDownloadResponseIcon {
  display: none;
}

.scoreSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  gap: 0.5rem;
  padding: 1rem;
  color: blue;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--highlight-color);
}

.previous {
  width: 1.3rem;
  height: 1.3rem;
}

.next {
  width: 1.3rem;
  height: 1.3rem;
  transform: rotate(180deg);
}

.quizSection {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: var(--border);
  padding: 1rem;
  color: blue;
  box-shadow: var(--box-shadow);
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--highlight-color);
}

.quizRaffleContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
}


.cardText {
  font-size: 12px;
  font-weight: bold;
}

.headCont {
  display: flex;
  gap: 1rem;
}

.downloadBtn {
  display: block;
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .userTable {
    width: 20px;
  }

  .leftPane {
    width: 20px;
  }


}


@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .leftPane {
    width: 20px;
  }
}


@media only screen and (max-width: 768px) {
  .leftPane {
    display: none;
  }

  .downloadBtn {
    display: none;
  }

  .headCont {
    width: 100%;
  }

  .headBtn {
    width: 100%;
  }

  .actionBtn {
    width: 100%;
  }

  .actionContainer {
    display: none;
  }

  .backBtnContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .responseContentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .quizRaffleContainer {
    margin-left: 0rem;
    flex-direction: column;
  }

  .responseFirstRow {
    flex-direction: column;
    gap: 1rem;
  }

  .responseText {
    width: 100%;
  }

  .gapIconResponseDetails {
    justify-content: flex-start;
    width: 100%;
    text-align: left;
  }

  .cardText {
    width: 100%;
  }

  .responseMobileFirstRow {
    display: none;
    flex-direction: column;
  }

  .responseMobileSecondRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  // .formSection {
  //   padding-top: 0;
  //   max-height: 100%;
  //   margin-left: 0;
  // }

  .formQuestionAnswerRow {
    align-items: self-start;
    // flex-direction: row;
    // padding: 0.4rem;
  }

  .singleDownloadResponseIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .scoreSection {
    width: 100%;
  }

  .quizSection {
    width: 100%;
  }
}

.markEligibleLink {
  font-size: 12px;
  width: 6rem;
  cursor: pointer;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  background: #9fd858;
  border-radius: var(--border-radius);
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5px;

}

.markInEligibleLink {
  font-size: 12px;
  width: 6rem;
  cursor: pointer;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  background: #ff5959;
  border-radius: var(--border-radius);
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.raffleWinnerBtn {
  font-size: 12px;
  width: 6rem;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  background: var(--primary-color);
  border-radius: var(--border-radius);
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
