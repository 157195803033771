.settingsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.optionalStyle {
  color: #828282;
  font-style: italic;
  font-size: 10px;
}

//global settings
.themeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.themeBar {
  display: flex;
  width: 5rem;
  height: 2rem;
  border: var(--border);
  border: 2px solid #e7e7e7;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.colorBox {
  border-radius: var(--border-radius) 0px 0px var(--border-radius);
  height: 100%;
  width: 100%;
}

.colorBox2 {
  border-radius: 0px var(--border-radius) var(--border-radius) 0px;
  height: 100%;
  width: 100%;
}

.infoText {
  color: var(--secondary-color);
  font-size: 12px;
  font-weight: bold;
}


//header settings
.fontContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.fontLayout {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 1px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--secondary-color);
}

input:focus+.slider {
  box-shadow: var(--box-shadow);
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorText {
  color: red;
  font-size: 12px;
}

//timer setting
.selectedTimeInput {
  width: 2.35rem;
  height: 2.1rem;
  color: #000000;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-color: var(--highlight-color);
  cursor: pointer;

}

.timeInput {
  width: 2.35rem;
  height: 2.1rem;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-color: white;
  cursor: pointer;
}

.timeSettings {
  margin-right: 20px;
  margin-top: 1rem;
  display: inline-block;
  flex: 1;
  opacity: 1;
  top: 488px;

}

.selectedTimeSettings {
  color: var(--highlight-color);
  margin-right: 20px;
  margin-top: 1rem;
  display: inline-block;
  flex: 1;
  opacity: 1;
}

.saveBtnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  width: calc(100% + 2px);
}

.fontIsRed {
  color: red;
}

.hasLineBreak {
  padding-top: 1rem;
}

.selectedTheme {
  border: 2px solid var(--secondary-color);
}

.timerBtnContainer {
  display: flex;
  gap: 0.75rem 1rem;
  flex-wrap: wrap;
}

.themesClear {
  margin-left: auto;
  text-decoration: underline;
  font-size: 0.75rem;
  height: fit-content;
  margin-top: auto;
  cursor: pointer;
}

.fontClearBtn {
  width: 1rem;
  height: 1rem;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
}

.emailNotification {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.info {
  font-size: 12px !important;
  color: #828282;
  font-style: italic;
}

//translation settings
.sliderDisabled {
  pointer-events: none;
  cursor: not-allowed;
}

.customMessageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reqContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: var(--border-radius);
}

.preContainer {
  white-space: pre;
}

.disappear {
  display: none;
}

.jsonContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 0.5rem;
}

.jsonBody {
  display: flex;
  flex-direction: column;
  background: #000;
  padding: 0.5rem;
  box-sizing: border-box;
  color: #fff;
  font-family: monospace;
}

.jsonContent {
  padding-left: 1rem;

  span {
    font-weight: bold;
  }
}

.linkBtn {
  color: var(--secondary-color);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.postMessageInputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}