.leftContainer {
  background: var(--background-color);
  // border: 1px solid #000000;
  box-shadow: var(--box-shadow);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.signUpBackgroundImage {
  align-self: center;
  width: 90%;
  margin: auto;
}

.mainTitle {
  color: var(--secondary-color);
  font-weight: 500;
  font-size: 25px;
  padding: 2rem;
  text-align: center;
}

.subTitle {
  color: var(--font-color);
  font-weight: 500;
  font-size: 25px;
}

.content {
  @extend .subTitle;
  font-size: 16px;
  text-align: center;
  padding: 0 4rem 0 4rem
}

.contentTitle {
  @extend .mainTitle;
  font-size: 16px;
  text-align: center;
}

.imageContainer {
  align-self: center;
  padding: 7rem;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .leftContainer{
    display: none;
  }
}
