.mainContainer {
  padding: 1rem 1.5rem;
  padding-top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  background: var(--background-color);
  overflow: auto;
}

.outletContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
}

.actionMenuBg {
  display: none;
}

.mobileActionPanel {
  display: block;
}

@media only screen and (max-width: 768px) {
  .mobileActionPanel {
    display: none;
  }

  .outletContainer {
    overflow: auto;
  }

  .mainContainer {
    overflow: hidden;
    padding-bottom: 2rem;
  }

  .actionMenuBg {
    display: block;
    width: 100%;
    min-height: 100dvh;
    background: var(--background-color);
    position: fixed;
    top: 0;
    z-index: -1;
    left: 0;
  }

  .actionMenuBgActive {
    z-index: 999;
  }

  .mobileFilterContainer {
    border: var(--border);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--secondary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 20rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }
}
