.page {
  padding: 1rem;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: #F8F8F8;
  transition: all 200ms;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.controlsContainer {
  flex-grow: 1;
}

.pageFull {
  margin-bottom: 0;
  min-height: 100%;
}

.emptyPage {
  min-height: 8rem;
  flex-grow: 1;
  display: flex;
}

.deleteContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.hoverBtn {
  align-self: flex-end;
  background: #F8F8F8;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
  cursor: pointer;
}

.hoverBtn:hover {
  background: var(--highlight-color);
}

.topPage {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 0;
}

.soloPage {
  min-height: calc(100% - 5.5rem);
  margin-bottom: 0;
}

.checkTooltip {
  position: relative;
  align-self: flex-end;
}

.checkTooltip:hover {
  &::before {
    content: attr(tool-tip);
    left: -10px;
    position: absolute;
    color: black;
    background: white;
    font-size: 0.75rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 0.25rem 0.5rem;
    border: var(--border);
    margin-right: 0.5rem;
    bottom: 50%;
    transform: translateX(-100%) translateY(50%);
    white-space: pre;
  }

  &::after {
    bottom: 50%;
    left: -5px;
    margin-top: auto;
    margin-bottom: -5px;
    margin-left: -10px;
    content: "";
    position: absolute;
    top: 10px;
    right: -7px;
    width: 7px;
    height: 7px;
    background: #FFFFFF;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    box-shadow: var(--box-shadow);
  }
}

.field {
  display: flex;
  justify-content: flex-end;
  overflow-x: clip;
}

.addPlaceholderDiv {
  width: 100%;
  height: 7.25rem;
  background: white;
  border-radius: var(--border-radius);
  border: var(--border);
  pointer-events: none;
}

.builderPlaceholder {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.errorBoundary {
  border: 1px solid #FF1414;
  box-shadow: var(--box-shadow);
  color: #FF1414;
  background: rgba(255, 0, 0, 0.1) !important;
}

.pageErr {
  color: #FF1414;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  pointer-events: none;
}
