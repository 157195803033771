.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--primary-color);
  min-height: 100vh;
  border: 0;
  width: 5rem;
  box-sizing: border-box;
  z-index: 999;
  box-shadow: var(--box-shadow);
}

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  flex-grow: 1;
}

.cubeContainer{
  background: #fafafa;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 1rem 0.2rem;
}

.profileContainer {
  margin: 1rem;

  img {
    width: 2rem;
    height: 2rem;
    border-radius: var(--border-radius);
    background: white;
  }
}

.menuItems {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 500ms;
  display: flex;
  justify-content: center;
  transition: all 200ms;

  &:hover {
    transform: scale(1.1);
  }
}

.menuIcons {
  padding: 0.2rem 0;
}

.logOutContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.versionContent {
  color: #000;
  font-weight: 500;
  text-align: center;
  padding: 1rem;
}

.mobileContainer {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    display: none;
  }

  .menuContainer {
    display: none;
  }

  .menuItems {
    display: none;
  }

  .mobileContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 4rem;
    top: 0;
    width: 100%;
    border: none;
    min-height: auto;
    background: #f8f8f8;
    z-index: 2;
  }

  .hamContainer {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    background: var(--highlight-color);
  }

  .menuBtnContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.5rem;
    margin-top: 6rem;
    overflow-y: auto;
    padding-bottom: 6rem;
    height: 100dvh;
    gap: 1rem;
  }

  .mobileMenuBtn {
    background: transparent;
    color: #fff;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem
  }

  .menuFont {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Poppins";
  }

  .feedBackIcon {
    padding: 0.2rem 0.1rem;
    width: 1.7rem;
    height: 1.7rem;
  }

  .logoutBtn {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .versionMobileContent {
    color: #fff;
    font-weight: 600;
    font-style: 1rem;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .profileContainer {
    margin: auto;

    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.profileMenu {
  display: none;
}

.activeProfile {
  position: relative;

  .profileMenu {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    position: absolute;
    background: white;
    padding: 0.5rem 0;
    border-radius: var(--border-radius);
    bottom: -10%;
    box-sizing: border-box;
    left: calc(100% + 1rem);
    border: var(--border);
    box-shadow: var(--box-shadow);
    white-space: nowrap;
    font-size: 14px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 12px;
      left: -17px;
      margin: auto;
      border-width: 9px;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
    }

    * {
      padding: 5px 1rem;
      color: black;

      &:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}

.settingsMenu {
  display: none;
}

.line {
  height: 1px;
  background: #828282a9;
  position: relative;
  padding: 0 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.activeSettings {
  position: relative;

  .settingsMenu {
    display: flex;
    gap: 0.25rem;
    box-sizing: border-box;
    flex-direction: column;
    position: absolute;
    background: white;
    padding: 0.5rem 0;
    border-radius: var(--border-radius);
    top: -6px;
    left: calc(100% + 0.5rem);
    border: var(--border);
    box-shadow: var(--box-shadow);
    white-space: nowrap;
    font-size: 14px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 15px;
      left: -17px;
      margin: auto;
      border-width: 9px;
      border-style: solid;
      border-color: transparent #d5d5d5 transparent transparent;
    }

    * {
      padding: 5px 1rem;
      color: black;

      &:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}

.raffleClosebtn {
  background: var(--accent-color);
  border-radius: 48px;
  border: none;
  padding: 0.2rem;
  display: flex;
  box-shadow: none;
  cursor: pointer;

  img{
    width: 1.2rem;
  }
}

.closImg {
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;

  &:active {
    transform: scale(0.9);
  }
}

.headerContainer {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  gap: 1rem;
}


.isAccordianContent {
  width: 100%;
  max-height: 0;
  background: #fff;
  transition: all .35s;
}

.isAccordianContentActive {
  height: 5rem;
  max-height: 5rem;
  transition: all .35s;
}

.mobileCloseBtn {
  position: absolute;
  top: 5%;
}

.closebtn {
  background: #fff;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  outline: none;
  border: none;
  cursor: pointer;
  padding: none !important;

  img{
    width: 1rem;
  }
}

.mobileSubMenu {
  width: 100%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
