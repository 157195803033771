.container {
  margin: auto;
}

.prevButton,
.nextButton {
  padding: 0.75rem;
  background-color: var(--accent-color);
  border-radius: var(--border-radius);
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &.disabled {
    background-color: #AADAFF;
    cursor: not-allowed;
  }
}

.paginationContainer {
  width: 100%;
  height: 100%;
  gap: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 0;
  }
}

.disableBtn {
  opacity: 0.25;
  cursor: not-allowed;
}

.activePage {
  background: var(--secondary-color);
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 0.3rem 0.8rem;
  color: #fff;
  pointer-events: none;

}

.page {
  background: #F8F8F8;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 0.3rem 0.8rem;
  cursor: pointer;
}

.page:hover {
  background-color: #F7F7F7;
}

.doubleLeftArrow {
  cursor: pointer;
  height: unset;
  max-width: unset;
}

.leftArrow {
  transform: rotate(180deg);
  width: 0.5rem;
  align-items: center;
  height: unset;
  max-width: unset;
}

.rightArrow {
  width: 0.5rem;
  align-items: center;
  height: unset;
  max-width: unset;
}

.doubleArrow {
  width: 0.75rem;
  color: #000;
  fill: #000;
  display: inherit;
  margin: 0 3px;
  height: unset;
  max-width: unset;
}

.wrapDimension {
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.nextImg {
  width: 1rem;
  height: 1rem;
}
