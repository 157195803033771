.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: var(--border);
}

.main {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  padding-top: 0;
  gap: 1rem;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: scroll;
}

.headingContainer {
  display: flex;
  padding: 0.5rem 1rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  align-items: center;
}

.subHeadingContainer {
  flex-direction: column;
}

.pageContainer {
  flex-grow: 1;
}

.tableRow {
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
}

.headRow {
  @extend .tableRow;
  font-weight: bold;
  background: var(--highlight-color);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  width: 25%;
  font-size: 14px;
}

.actionContainer {
  display: flex;
  gap: 1rem;
  overflow: unset;
}

.isImageHolder {
  width: 1rem;
  height: 1rem;
}

.loaderContainer {
  position: fixed;
  left: 50%;
  margin-left: 6rem;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
}


.loaderCube {
  position: absolute;
  left: 50%;
  top: 5rem;
  z-index: 99999;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 300ms ease-in-out, z-index 300ms;

  &.loaderClose {
    opacity: 0;
    z-index: -1;
  }
}

.contentPane {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  // padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.contentReady {
    opacity: 1;
  }
}

.topCheckToolTip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    background: white;
    border-radius: var(--border-radius);
    padding: 0.25rem 0.5rem;
    z-index: 99999;
    text-align: center;
    top: 0;
    right: auto;
    bottom: auto;
    line-height: 1;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, -120%);
    border: var(--border);
    box-shadow: var(--box-shadow);
  }
}

.mobileCard {
  display: none;
}

.topBtnContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

@media only screen and (max-width: 768px) {

  .userTable {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    margin: 0;
    gap: 1rem;
  }

  .titleContainer {
    padding: 1rem 0rem;
  }

  .headRow {
    display: none;
  }

  .tableRow {
    display: none;
  }

  .mobileCardContainer {
    display: block;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    gap: 1rem;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #828282a9;
    position: relative;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .addBtnContainer {
    width: auto;
    position: fixed;
    bottom: 1rem;
    left: 2rem;
    right: 2rem
  }
}
