.loaderContainer{
    width:5rem;
    height:5rem;
    border: 4px solid rgba(0,0,0,0.3);
    position:relative;
    border-radius:10px;
  }

  .container{
    position: fixed;
    top: 48%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .loader{
    width:2rem;
    height:2rem;
    background:var(--secondary-color);
    position:absolute;
    border-radius:50%;
    margin:5px;
    animation: loaderAnimation 1s linear 0s infinite;
  }

  @keyframes loaderAnimation{
    0%{
     top:0;
     background:var(--secondary-color);
    }
    25%{
      background:var(--secondary-color);
    }
    50%{
      right:0;
      background:var(--primary-color);
    }
    75%{
       background:var(--secondary-color);
    }
    100%{
      bottom:0;
      background:var(--primary-color);
    }
  }

  .container{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // .mainLoader{
  //   position: fixed;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  // }

  .parent {
    width: 100px;
    height: 100px;
    border: 4px solid rgba(0,0,0,0.1);
    border-radius: 50%;
  }

  .item {
    width: 25px;
    height: 25px;
    background-color: var(--primary-color);
    border-radius: 50%;
    offset-path: path("M50,0 A50,50 0 1,1 50,100 A50,50 0 1,1 50,0");
    animation: move 2s linear infinite;
  }

  @keyframes move {
    from {
      offset-distance: 0%;
    }

    to {
      offset-distance: 100%;
    }
  }
