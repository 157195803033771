.customNode {
  width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
}

.customNodeContainer {
  padding: 0.5rem;
  border: var(--border);
  background: #ffeddd;
  border-radius: 4px;
}

.pageCtrl {
  background: #e3deff;
}

.secControl {
  background: #ffc28b;
}
