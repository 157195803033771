.container {
  padding: 2rem;
  min-height: 100vh;
  box-sizing: border-box;
  transition: all 250ms linear;
}

.container * {
  font-size: 14px;
}

.builderContainer {
  display: flex;
  padding: 1.5rem;
  box-sizing: border-box;
  min-height: calc(100vh - 6.5rem);
  max-height: calc(100vh - 6.5rem);
  gap: 2rem;
}

.btn {
  background: var(--secondary-color);
  outline: none;
  box-shadow: var(--box-shadow);
  border: var(--border);
  padding: 8px 8px;
  border-radius: 4px;
  font-size: 14px;
  width: 25%;
  transition: all 250ms ease-in;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
}

.optionalStyle {
  color: #828282;
  font-style: italic;
  font-size: 10px;
}

.infoText {
  color: var(--primary-color);
  font-size: 12px;
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
}

.blurContainer {
  filter: blur(3px) grayscale(100%);
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.fontIsRed {
  color: red;
}

.mb1 {
  margin-bottom: 0.75rem;
}

.saveBtnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.errorText {
  font-size: 10px;
  margin-top: 0.5rem;
  color: red;
}

.builderCanvas {
  border: var(--border);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
}

.formCanvasTitle {
  padding: 1.5rem 2rem;
  font-weight: bold;
  font-size: 18px;
}

.linebreak {
  background: #828282;
  width: 95%;
  height: 1px;
  margin: 0 auto;
}

.boldFont {
  font-weight: bold;
  font-size: 14px;
}
