.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.paymentMenthodContainer {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.cardDetails {
  display: flex;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 1rem;
  gap: 0.5rem;
  //width: 30%;
  transition: all 250ms;
  justify-content: center;
  align-items: center;
}

.cardDetails:hover {
  background: var(--highlight-color);
}

.cardContainer {
  display: flex;
  gap: 1rem;
}

.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: var(--border);
}

.tableRow {
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
}

.tableRow:hover {
  background: var(--highlight-color);
}

.headRow {
  @extend .tableRow;
  font-weight: bold;
  background: var(--highlight-color);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  width: 25%;
  font-size: 14px;
}

.actionContainer {
  display: flex;
  gap: 1rem;
}

.cardHeading {
  font-weight: bold;
  font-size: 1rem;
}

.deleteContainer {
  margin-left: 3rem;
  align-self: flex-start;
}

.cardDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radioContainer {
  align-self: flex-start;
}

.paginationContainer {
  margin-top: 10px;
  width: 100%;
}

.paginationItems {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.container {
  display: none;
}

.downloadLink {
  color: hsl(229deg, 53%, 53%);
}

a:visited,
a:hover,
a:link,
a:active {
  color: hsl(229deg, 53%, 53%);
}

@media only screen and (max-width: 768px) {

  .userTable {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .deleteContainer {
    margin-left: 2rem;
    display: unset;
    margin-right: 1rem;

  }

}

.billingInfoContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.changeBillingText {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.cardDetails {
  border: var(--border);
  display: flex;
  border-radius: var(--border-radius);
  padding: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 5px #d5d5d5;
    cursor: pointer;
  }
}

.cardDetailsBilling {
  border: var(--border);
  display: flex;
  border-radius: var(--border-radius);
  padding: 1rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 5px #d5d5d5;
    cursor: pointer;
  }
}

@media screen and (max-width: 425px) {
  .cardDetails {
    width: 100%;
  }
}

.cardContainer {
  display: flex;
  gap: 1rem;
}

.cardHeading {
  font-weight: bold;
  font-size: 12px;
}

.cardDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container {
  border: var(--border);
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
}

.titleContainer {
  padding-bottom: 1rem;
}

.error {
  font-size: 12px;
  margin-top: 1rem;
  color: #ff0000;
}

.activeCard {
  border: 1px solid;
  background-color: #e9e9e9;

  &:hover {
    cursor: pointer;
    background-color: #e9e9e9;
  }
}

.containerErr {
  border-color: #ff0000;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 40rem;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--secondary-top-bar-color);
  border-radius: 4px;
}

.activeCard {
  border: 1px solid;
  background-color: #e9e9e9;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--secondary-top-bar-color);
  border-radius: var(--border-radius);
  padding: 1rem;
}
