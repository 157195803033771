.main {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  padding: 1rem;
  min-height: 100vh;
  gap: 1rem;
  box-sizing: border-box;
}

.pageContainer {
  display: grid;
  //grid-template-columns: 0.2fr 1fr;
  background: #FFFFFF;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #000000;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  flex-grow: 1;
}

.parent {
  position: relative;
  display: block;
  top: 30px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  //margin-bottom: auto;

}

.successbtn {
  position: absolute;
  height: 166px;
  top: 22%;
  left: 35%;
}

.successtick {
  position: absolute;
  top: 30%;
  left: 40%;
}

.alignCenter {
  position: relative;
  display: block;
  top: -100px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.paymentText {
  font-size: 18px;
  font-family: 'Poppins';
}

.signBtn {
  display: flex;
  justify-content: center;
  //width: 100%;
  width: 20rem;
  gap: 1rem;
}

.successText {
  position: absolute;
  color: #5cb85c;
  font-size: 18px;
  font-weight: 600;
  top: 60%;
  left: 34%;
}
