.mainContainer {
  display: flex;
  background: #ffff;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: clamp(2rem, 7vw - 1rem, 10rem);
  margin: auto;
  gap: 1rem;
}

.signUpTextOne {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
}

.errorText {
  color: red;
  font-size: 10px;
  margin: 0;
  font-family: "Poppins";
}

.directLoginBtnContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.directLoginContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.signUpTextThree {
  font-size: 14px;
}

.forgotBtn {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.login {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 7%;
  padding: 6px;
}

.pageLayout {
  display: contents;
}

.resetInput {
  flex-direction: column;
  display: flex;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .rightContainer {
    padding: 0 1rem;
    width: 100%;
  }

  .mainContainer {
    background: var(--background-color);
  }

  .login {
    width: 20%;
  }

  .pageLayout {
    display: none;
  }
}

// /* portrait */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
//   .rightContainer {
//     padding: 0 1rem;
//   }

// }

// /* Landscape */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
//   .rightContainer {
//     padding: 0 3rem;
//   }
// }

// @media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
//   .rightContainer {
//     padding: 0 5rem;
//   }
// }
