.main {
  border: var(--border);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F8F8F8;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
}

.backBtnContainer {
  align-self: unset;
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #eeeeee;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.titleText {
  font-size: 16px !important;
}

.titleContainer {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}


.downloadAsContainer {
  background-color: beige;
  margin-right: 2rem;
  width: 9%;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  height: 80%;
  flex-grow: 1;
  flex-basis: auto;

}

.leftPane {
  width: 30%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
}


.leftFirstContainer {
  display: flex;
  justify-content: space-between;
}

.participantsRow {
  display: flex;
  border: var(--border);
  padding: 1rem;
  border-radius: var(--border-radius);
  background: var(--background-color);
  gap: 1rem;
}

.leftPaneFirstColumn {
  display: flex;
  flex-direction: column;
}

.leftSecondContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftThirdContainer {
  display: flex;
  justify-content: space-between;
}

.firstgif {
  height: 20rem;
}

.verticalLine {
  border-left: 1px solid #828282;
}


.userTableMainDiv {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.userTableMainDiv::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}

.userTableMainDiv::-webkit-scrollbar-track {
  background: transparent;
}

.userTableMainDiv::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}

.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  max-height: 70vh;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.headRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  padding: 1rem;
  cursor: default;
  display: flex;
}

.tableRow {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  transition: all 500ms;
  height: 5rem;
  border-radius: var(--border-radius);
  border: var(--border);
  box-shadow: var(--box-shadow);
  padding: 0.5rem;
}

.tableRow:hover {
  cursor: default
}

.tableDataHead {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  font-size: 1rem;
}

.tableData {
  display: flex;
  flex-direction: column;
  width: 28%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.userTableComplete {
  flex-basis: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  gap: 1rem;
  background: #fff;
}

.mobileMain {
  display: none;
}

.downloadSlider {
  display: none;
}

.mobileCloseBtn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .main {
    display: none;
  }

  .titleContainer {
    margin-top: 2rem;
  }

  .mobileMain {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .leftPane {
    width: 100%;
    padding: 0;
  }

  .participantsRow {
    justify-content: space-between;
  }

  .leftFirstContainer {
    flex-direction: column;
    gap: 1rem;
  }


  .leftSecondContainer {
    margin-left: 0rem;
  }

  .leftThirdContainer {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

  }

  .horizontalLine {
    margin-top: 1rem;
    height: 75%;
    border: var(--border);

  }

  .tableDataHead {
    display: flex;
    width: 60%;
    font-size: 0.9rem;
    justify-content: center;
    gap: 5rem;
  }

  .tableData {
    display: flex;
    width: 50%;
    align-items: center;
    flex-direction: column;
    font-size: 0.75rem;

  }

  .headRow {
    display: none;
  }

  .tableRow {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .userTableComplete {
    padding: 0;
    gap: 0rem;
    min-height: unset;
    max-height: 55vh;
    overflow: auto;
  }

  .downloadSlider {
    display: block;
    position: fixed;
    bottom: 5px;
    left: 2rem;
    right: 2rem;
  }

  .mobileFilterContainer {
    box-shadow: var(--box-shadow);
    border: var(--border);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: #642DFB;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 12rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .mobileFilterContainer {
    width: 100%;
    left: 0%;
    overflow-y: none;
  }

  .tagTitle {
    color: #fff;
  }

  .tagTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobileCloseBtn {
    display: contents;
  }


  .closebtn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .line {
    width: 95%;
    border: var(--border);
    position: relative;
    bottom: 0.1px;
  }

  .downloadContainer {
    display: flex;
    width: 100%;
  }

  .filterHolder {
    float: left;
    margin: 3%;
    flex-wrap: wrap;
  }

  .tag {
    height: 2rem;
    width: 5rem;
    background: #F8F8F8;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    transition: all 250ms;
    cursor: pointer;
  }

  .tag:hover {
    cursor: pointer;
    box-shadow: var(--box-shadow);box-shadow: 0px 1px 0px #000000;
  }

  .cardCont {
    display: flex;
    gap: 1rem;
    border: var(--border);
    box-sizing: border-box;
    padding: 0.5rem;
    align-items: center;
    border-radius: var(--border-radius);
    font-size: 14px;
  }

  .cardContentCont {
    display: flex;
    flex-direction: column;
  }

  .cardContent {
    display: flex;
    gap: 0.5rem;
  }

  .mobileCardCont {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .leftFirstContainer {
    margin-left: 1rem;
  }

  .leftThirdContainer {
    margin-left: 1rem;
  }

  .titleContainer {
    margin-left: 0rem;
  }

  .leftPane {
    width: 100%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {}
