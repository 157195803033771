.container {
  border: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flexContainer {
  display: flex;
  gap: 1rem;
}

.metricContainer {
  padding: 1rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: 25%;
  text-align: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.isImageHolder {
  width: 1.2rem;
  height: 1.2rem;
}

@media screen and (max-width: 768px) {
  .flexContainer {
    flex-wrap: wrap;
  }

  .metricContainer {
    box-sizing: border-box;
    width: calc(50% - 0.5rem);
  }
}