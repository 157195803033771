.main {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  padding: 1rem;
  min-height: 100vh;
  gap: 1rem;
  box-sizing: border-box;
}

.pageContainer {
  display: grid;
  //grid-template-columns: 0.2fr 1fr;
  background: var(--highlight-color);
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #000000;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  flex-grow: 1;
}

.parent{
  position: relative;
  display: block;
  top: -60px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  //margin-bottom: auto;

}


.failexclamatory1{
  position: absolute;
  top: -140px;
  left: -100px;
}


.failexclamatory2{
  position: absolute;
  top: 90px;
  left: 170px;
}

.failcross1{
  position: absolute;
  top: -73px;
  left: 122px;
}
.failcross2{
  position: absolute;
  top: 10px;
  left: -150px;
}

.successbtn{
  position: relative;
  top: 0;
  left: 0;
  height: 166px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // position: absolute;
  // height: 166px;
  // top: 22%;
  // left: 35%;
  // height: ;
}

.successtick{
  position: absolute;
  top: 33%;
  left: 42%;
}

.alignCenter{
  position: relative;
  display: block;
  top: 20px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.paymentText{
  font-size: 18px;
  font: "Poppins"
}

.signBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.successBackground{
  position: relative;
}

.successText{
  position: relative;
  color: red;
  font-size: 18px;
  font-weight: 600;
  top: 0;
  left: 34%;
  margin-left: auto;
  margin-right: auto;
}
