//slider switch
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.infoText {
  color: var(--primary-color);
  font-size: 14px;
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 1px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--secondary-color);
}

input:focus+.slider {
  box-shadow: var(--box-shadow);
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.requiredContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.notifyScore {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.customDropDownMenu {
  width: 100%;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  outline: none;
}

.css-t3i-psp-control:hover {
  border-color: var(--primary-color) !important;
}

.toolTipImg {
  width: 0.8rem;
  height: 0.8rem;
}

.labelContainer {
  display: flex;
  gap: 0.5rem;
}

.propertyContainer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.saveBtnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 1rem 0 1rem 1rem;
}

.btn {
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: white;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
}

.btn:hover {
  background: var(--highlight-color);
}

.active {
  background: var(--highlight-color);
  color: #000000;
}

.formatContainer {
  display: flex;
  gap: 1rem;
}

.textFormatContainer {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.optionContainer {
  display: flex;
  position: relative;
  align-items: center;
  gap: 1rem;
}

.removeButton {
  background: none !important;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 8px;

  &:disabled {
    opacity: 0.5;
  }
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

//tooltip
.checkTooltip:hover {
  &::before {
    content: attr(tool-tip);
    left: -10px;
    position: absolute;
    color: black;
    background: white;
    font-size: 0.75rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 0.25rem 0.5rem;
    border: var(--border);
    margin-right: 0.5rem;
    bottom: 50%;
    transform: translateX(-100%) translateY(50%);
    white-space: pre;
  }

  &::after {
    bottom: 50%;
    left: -5px;
    margin-top: auto;
    margin-bottom: -5px;
    margin-left: -10px;
    content: "";
    position: absolute;
    top: 10px;
    right: -7px;
    width: 7px;
    height: 7px;
    background: #FFFFFF;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    box-shadow: var(--box-shadow);
  }
}

.topCheckToolTip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.85);
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    bottom: auto;
    transform: translateX(-50%) translateY(-100%);
    right: auto;
    left: 50%;
    top: 0;
    z-index: 99999;
    margin-top: -7px;
    margin-bottom: 0;
    min-width: 8rem;
    text-align: center;
  }

  &::after {
    bottom: auto;
    left: 50%;
    margin-top: -7px;
    margin-bottom: auto;
    margin-left: -10px;
    content: "";
    position: absolute;
    top: 0px;
    right: auto;
    margin-right: auto;
    z-index: 99999;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.85);
  }
}

.bottomCheckToolTip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.85);
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    z-index: 99999;
    min-width: 8rem;
    text-align: center;
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 99999;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.85);
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -10px;
  }
}
