.content {
  max-height: 0;
  overflow: hidden scroll;
  pointer-events: none;
}

.mb1 {
  margin-bottom: 0.5rem;
}

.contentOpen {
  height: fit-content;
  max-height: 100vh;
  pointer-events: all;
  overflow: visible;
  box-sizing: border-box;
  padding: 12px;
  border: var(--border);
  border-radius: 4px;
}

.content::-webkit-scrollbar-track {
  background: transparent;
}

.content::-webkit-scrollbar-thumb {
  background: transparent;
}

.content::-webkit-scrollbar {
  display: none;
}

.btnContainer {
  display: flex;
  align-items: center;

  img {
    margin-left: auto;
  }
}

.arrow {
  transition: all 300ms ease-in-out;
  height: 1rem;
  width: 1.5rem;
}

.arrowUp {
  transform: rotate(180deg);
}
