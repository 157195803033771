.sidepaneMain {
    background: #ffffff;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.apiKeyCopyContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.textColor {
    color: #000000;
}

.generateBtn {
    display: block;
}

.linkLabel {
    color: #000;
}

.fieldContainer {
    display: flex;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    border: var(--border);
}

.copyInputContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.erroraAPIKeyName {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 10px;
    color: #f008088d;
}

@media only screen and (max-width: 768px) {
    .sidepaneMain {
        padding: 2rem;
        background: var(--secondary-color);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        max-height: rem;
        overflow-y: auto;
    }

    .topContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .line {
        width: 100%;
        height: 1px;
        background: #f8f8f8;
        position: relative;
    }

    .textColor {
        color: #ffffff;
    }

    .infoText {
        color: #fff;
    }

    .mobileCloseBtn {
        display: block;
    }

    .closebtn {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        padding: none !important;
    }

    .mobilePaneCloseBtn {
        background: #ffffff;
        border-radius: 48px;
        border: none;
        padding: 0.2rem;
        display: flex;
        box-shadow: none;
        cursor: pointer;
    }

    .closeBtnContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .generateBtn {
        display: none;
    }
}