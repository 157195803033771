.customTextara {
  background: #FFF;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  transition: all 250ms ease-in;
  resize: none;
  min-height: 5rem;
  width: 100%;
  box-sizing: border-box;
}

.customTextara:focus{
  box-shadow: var(--box-shadow);
}

.disableInput{
  pointer-events: none;
}
