.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  padding-right: 1.2rem;
  box-sizing: border-box;
  background: var(--background-color);
  gap: 1rem;
  padding-top: 0;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.yourFormsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

.viewContainer {
  align-self: flex-end;
}

.containerOne {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.titleContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.topContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 1rem 1.5rem;
  margin-top: 0;
}

.containerTwo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.createForm {
  display: flex;
}

.noFormsContainer {
  background: var(--accent-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius);
  justify-content: center;
}

.cardContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
  text-align: center;
}

.card {
  background: #F8F8F8;
  border: var(--border);
  border-radius: var(--border-radius);
  // width: 18%;
  flex: 1 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: all 250ms;
  gap: 1rem;
}

.tableContainer {
  flex-grow: 1;
}

.card:hover {
  background: var(--highlight-color);
  cursor: pointer;
}

.cardImage {
  background: var(--highlight-color);
  padding: 1rem 1.2rem;
  border-radius: 50%;
}

.actionContainer {
  display: flex;
  gap: 1rem;
}

.backImgConatiner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.backImg {
  width: 1rem;
  height: 1rem;
}


.loaderContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
}


.cardFont {
  color: #3D3D3D;
  font-weight: 600;
  font-size: 14px;
}

.contentContainer {
  pointer-events: none;
  user-select: none;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}


.loaderCube {
  position: absolute;
  left: calc(100vw / 2 - 5rem);
  top: calc(100vh / 2 - 10rem);
  z-index: 99999;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 300ms ease-in-out, z-index 300ms;

  &.loaderClose {
    opacity: 0;
    z-index: -1;
  }
}

.contentPane {
  opacity: 0;
  flex-grow: 1;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  display: flex;
  width: 100%;

  &.contentReady {
    opacity: 1;
  }
}

.paginationContainer {
  background: var(--secondary-top-bar-color);
  padding: 1rem;
  position: fixed;
  box-shadow: var(--box-shadow);
  bottom: 0;
  left: 6rem;
  right: 1rem;
}

.mobileViewAllContainer {
  display: none;
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .cardFont {
    font-size: 12px;
  }

  .topContainer {
    width: 70%;
  }

  .viewContainer {
    width: 15%;
  }

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .cardFont {
    font-size: 14px;
  }

  .topContainer {
    width: 45%;
  }

}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .topContainer {
    width: 45%;
  }
}

.addBtn {
  padding: 0.25rem 0.75rem;
  background: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.mobileTitleContainer {
  display: none;
}

.mobileViewTemplate {
  display: none;
}

.container {
  display: none;
}

.tabContainer {
  display: flex;
}

.paginationWrapper {
  display: none;
}

@media only screen and (max-width: 768px) {

  .mainContainer {
    padding-top: 1rem;
  }

  .topContainer {
    display: none;
  }

  .paginationWrapper {
    display: block;
  }

  .contentContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .paginationContainer {
    left: 1rem;
  }

  .createForm {
    display: none;
  }

  .mobileTitle {
    word-break: break-word;
  }

  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .hideAddForm {
    display: none;
  }

  .topContainer {
    flex-direction: column;
  }

  .tabContainer {
    display: none;
  }

  .searchMobileContainer {
    width: clamp(2.5rem, 2vw - 1rem, 3rem);
    align-self: flex-end;
  }

  .mobileTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .searchContainer {
    display: none;
  }

  .expandSearchContainer {
    width: 50%;
    transition: width .5s;
  }

  .mainContainer {
    gap: 1rem;
  }

  .card {
    min-width: 10rem;
    height: 10rem;
    border-radius: var(--border-radius);
    gap: 0rem;
    padding: 1rem;
  }

  .cardContainer {
    overflow-x: auto;
    width: 109%;
    gap: 1rem;
    padding: 0 0 1rem 0;
  }

  .card:last-child {
    margin-right: 1rem
  }

  .viewContainer {
    display: none;
  }

  .mobileViewTemplate {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .viewAllBtn {
    background: none;
    border: none;
    outline: none;
    color: #828282;
    font-size: 14px;
    font-family: Poppins;
  }

  .cardFont {
    font-size: 14px;
  }
}
