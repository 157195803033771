@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');


.mainContainer {
  display: flex;
  background: #ffff;
  height: 100vh;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: clamp(2rem, 7vw - 1rem, 10rem);
  margin: auto;
  gap: 1rem;
}

.signUpFont {
  color: #707070;
}

.verifyOtpTextOne {
  @extend .signUpFont;
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.verifyOtpTextTwo {
  color: black;
  font-weight: bold;
  font-size: 25px;
}

.verifyOtpTextThree {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

.otpContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.resendOtpText {
  color: var(--secondary-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.emailIconContainer {
  display: flex;
}

.mobileIconContainer {
  display: flex;
}

u:hover {
  cursor: pointer;
}

.errorText {
  color: red;
  font-size: 10px;
  margin: 0;
  font-family: "Poppins";
}

.login {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 7%;
  padding: 6px;
}

.pageLayout {
  display: contents;
}

.alignResendOtp {
  align-self: flex-end;
}

@media only screen and (max-width: 768px) {

  .mainContainer {
    background: var(--background-color);
  }

  .rightContainer {
    width: 100%;
  }

  .login {
    width: 20%;
  }

  .pageLayout {
    display: none;
  }
}

.freezeResend {
  pointer-events: none;
  opacity: 0.5;
}

.enableResend {
  text-decoration: unset;
  font-weight: normal;
  font-size: 12px;

  &:hover {
    cursor: unset !important;
  }
}
