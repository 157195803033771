.customSelect {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  border: var(--border);
  box-shadow: none;
  border-radius: var(--border-radius);
  outline: none;
  color: #000000;
}

.css-olqui2-singleValue {
  color: #000000;
}
