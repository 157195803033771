.container {
  flex-grow: 1;
  background: transparent;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  display: flex;
  border: var(--border);
  box-shadow: var(--box-shadow);
}
