.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.section {
  border-radius: var(--border-radius);
  background: var(--background-color);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.parentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.btnContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}


@media screen and (max-width:768px) {
  .parentContainer {
    padding: 0;
  }

  .section {
    padding: 2rem;
    box-sizing: border-box;
  }

  .content {
    width: auto;
    margin: unset;
  }
}
