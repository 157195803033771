$font-color: #000000;

.header{
    color: #000;
    font-weight: 600;
}

.headerOne {
    @extend .header;
    font-size: 25px !important;
}

.headerTwo {
    @extend .header;
    font-size: 21px !important;
}

.headerThree {
    @extend .header;
    font-size: 18px !important;
}

.headerFour {
    @extend .header;
    font-size: 16px !important;
}

.headerFive {
    @extend .header;
    font-size: 14px !important;
}

.headerSix {
    @extend .header;
    font-size: 12px !important;
}
