.container {
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  transition: filter 250ms linear, opacity 250ms linear;
}

.container * {
  font-size: 14px;
}

.builderContainer {
  display: flex;
  padding: 1rem 0;
  box-sizing: border-box;
  min-height: calc(100vh - 10rem);
  max-height: calc(100vh - 10rem);
  gap: 1rem;
}

.blurContainer {
  filter: blur(3px) grayscale(100%);
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
  // background: #EEEEEE;
}

.loaderContainer {
  left: calc(100vw / 2 - 5rem);
  top: calc(100vh / 2 - 6rem);
  position: absolute;
}

// .blurContainer * {
//     background: #EEEEEE !important;
//     transition: opacity 30ms linear;
//     opacity: 0.75 !important;
// }
