.quizBtnContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.endQuizHeader {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.quizClosebtn {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 !important;
}

.closImg {
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;

  &:active {
    transform: scale(0.9);
  }
}

.headerContainer {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
}

.endQuizContent {
  word-wrap: break-word;
  display: flex;
  width: 80%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.checkTooltip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: black;
    background: white;
    font-size: 0.65rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 0.25rem 0.5rem;
    border: var(--border);
    margin-right: 0.5rem;
    top: 0;
    transform: translateX(0) translateY(-120%);
    white-space: pre;
  }
}
