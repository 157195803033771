.tagsInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  background: #F8F8F8;
  border: var(--border);
  color: #000;
  box-shadow: none;
  border-radius: var(--border-radius);
  outline: none;
  width: 100%;

  input {
    width: 100%;
    border: none;
    background-color: #f8f8f8;

    &:focus {
      outline: transparent;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.multiTag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  list-style: none;
  background: var(--secondary-color);
  padding: 0.25rem 0.5rem;
  border: var(--border);
  cursor: pointer;
  gap: 0.5rem;
  color: #fff;
  border-radius: var(--border-radius);
  word-break: break-word;

  img {
    cursor: pointer;
  }

  img:active {
    transform: scale(0.85);
  }
}

.tagCloseIcon {
  display: flex;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  color: var(--secondary-color);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.tagContainer{
  display: flex;
  justify-content: space-evenly;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
}
