.main {
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  transition: all 0.5;
  font-size: 14px;
  text-align: center;
}

.mainActive {
  display: none;
}

.crumbs {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10rem;
  color: var(--font-color);
}

.crumbsActiveTitle {
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .crumbs {
    max-width: 3rem;
  }
}
