@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');

.customInput {
  width: 100%;
  background: #fff !important;
  border: var(--border);
  color: #000;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: 8px 8px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  transition: all 250ms ease-in;
}

.customInput::-webkit-outer-spin-button,
.customInput::-webkit-inner-spin-button {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

::placeholder {
  color: #000;
  opacity: 0.4;
}

.customInput:focus {
  box-shadow: var(--box-shadow) !important;
}

.custom2 {
  @extend .customInput;
  background: #D3CCFF;
  color: #000000;
}

.custom2:hover {
  background: var(--primary-color);
}

.custom3 {
  @extend .customInput;
  background: #EEEEEE;
}

// .custom3:hover {
//     background: #D2D2D2;
//     color: #642DFB
// }

.custom4 {
  @extend .customInput;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
}

.custom4:hover {
  @extend .customInput;
  color: #642DFB;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
}

.disableInput {
  pointer-events: none;
  opacity: 0.5;
}

.extraPadding {
  padding-left: 1rem !important;
}

.leftLittleMoreExtraPadding {
  padding-left: 1rem !important;
}
