.tabHeadContainer {
    display: flex;
    gap: 1rem;
}

.tabHeading {
    display: flex;
    flex-grow: 1;
}

.tabBtn:first-child{
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.tabBtn:last-child{
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}


.tabBtn {
    box-sizing: border-box;
    background:#e7e7e7;
    border: none;
    border-bottom: 2px solid transparent;
    color:#000;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 250ms;
    font-family: 'Poppins';
}

.activeTab {
    border-bottom:2px solid var(--secondary-color);
    font-weight: bold;
    color:var(--primary-color);
}

.tabComponent {
    display: none;
}

.tabComponentActive {
    display: block;
}
