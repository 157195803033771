.addBtn {
  border: var(--border);
  border-radius: 4px;
  padding: 0.25rem 1rem;
  width: fit-content;
  cursor: pointer;
}

.logicContainer {
  padding: 1rem;
  border: var(--border);
  border-radius: 4px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.conBlock {
  border: var(--border);
  border-radius: 4px;
  max-width: 15%;
  min-width: 15%;
  font-weight: 600;
  text-align: center;

  div {
    padding: 0.4rem;
  }
}

.conLayout {
  max-width: 30%;
}

.errorText {
  color: #ff1414;
  font-size: 12px;
}
