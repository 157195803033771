.main {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-top: 0;
  padding-right: 1.2rem;
  gap: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--secondary-top-bar-color);
  border-radius: var(--border-radius);
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middleContainer {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.middleSubContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.middleInnerContainer {
  width: 100%;
  align-self: flex-end;
}

.bottomContainer {
  display: grid;
  grid-template-columns: 4fr 1.5fr 0.5fr;
  background: #ffff;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.saveBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.subscriptionDetailsContainer {
  display: flex;
  gap: 1rem;
}

.subscriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.changePlan {
  align-self: flex-start;
  justify-self: flex-end;
}

.planDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-self: center;
  width: 50%;
}

.bullets {
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardItem {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.animIn {
  animation-duration: 250ms;
  animation-name: slidein;
  animation-iteration-count: initial;
}

.animOut {
  animation-duration: 250ms;
  animation-name: slideout;
}

.verfiedTick {
  position: absolute;
  top: 25%;
  right: 2%;
}

.subMainContainer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.profileNameContainer {
  min-width: 13rem;
  border: var(--border);
  background: var(--secondary-color);
  min-height: 13rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profileName {
  font-size: 7rem;
  font-weight: 600;
  color: #0A0A0A;
}

.warningText {
  color: darkorange;
  font-size: 10px;
  margin-top: 10px;
  font-family: "Poppins";
}

@keyframes slidein {
  from {
    opacity: 0;
    width: 100%;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    width: 100%;
  }

  to {
    opacity: 0;
    width: 100%;
  }
}

.cancelledOnText {
  font-size: 12px;
}


@media only screen and (max-width: 768px) {
  .subMainContainer {
    flex-direction: column;
  }

  .subscriptionContainer {
    width: 100%;
  }

  .planDetailsContainer {
    width: 100%;
    justify-content: space-between;
  }
}


.flexTitle {
  display: flex;
  gap: 0.5rem;
}

.switchPlan {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
}

.subsDetailsContainer {
  display: flex;
  gap: 1rem;
}

.detailsPanel {
  border-radius: 4px;
  border: var(--border);
  padding: 1rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .subsDetailsContainer {
    flex-direction: column;
  }

  .detailsPanel {
    width: 100%;
    box-sizing: border-box;
  }
}

.alignCentre {
  text-align: center;
}

.statusComponent {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 14px;
}
