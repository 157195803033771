.actionPanel {
  position: fixed;
  background: #ffffff;
  border: var(--border);
  top: 5rem;
  min-height: calc(100vh - 6rem);
  right: 0;
  transform: translate3d(22rem, 0, 0);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  min-width: 22rem;
  max-width: 22rem;
  transition: transform 250ms linear;
  overflow-y: scroll;
  max-height: calc(100% - 9.5rem);
  z-index: 99999;
}

.parentCont {
  backdrop-filter: brightness(0.5) blur(3px);
  height: calc(100vh - 0rem);
  width: 100%;
  opacity: 0;
  transition: opacity 300ms linear, backdrop-filter 250ms linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
}

.closeBtn {
  background: var(--accent-color);
  border-radius: 48px;
  border: none;
  padding: 0.3rem;
  display: flex;
  box-shadow: none;
  cursor: pointer;

  img{
  width: 1.2rem;
  }
}

.isParentOpen {
  opacity: 1;
  pointer-events: all;
}

.actionPanel::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.actionPanel::-webkit-scrollbar-track {
  background: transparent;
}

.actionPanel::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}

.m1 {
  margin: 1rem;
}

.actionPanelOpen {
  transform: translate3d(0, 0, 0);
}

.titleContainer {
  display: flex;
  position: sticky;
  top: 0;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 0 1rem 0;
  z-index: 99999;
  transition: all 250ms;
  width: calc(100% + 2px);
  justify-content: space-between;

  img {
    margin-left: auto;
    cursor: pointer;
    opacity: 0.9;
    transition: all 100ms ease-in-out;
  }

  img:hover {
    opacity: 1;
  }

  img:active {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 768px) {
  .actionPanel {
    top: unset;
    min-width: auto !important;
    min-height: auto !important;
  }
}
