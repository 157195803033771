.wrapper{
  position: relative;
  background: var(--background-color);
}

.container{
  display: flex;
 align-items: center;
 flex-direction: column;
  gap: 1rem;
}

.imgContainer{
  @extend .container;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: all 1s;
}

.imgContainerActive{
  @extend .container;
  opacity: 1;
  left: 0;
}

.imgs{
  width: 100%;
  height:20rem;
}

.mainTitle {
  color: var(--secondary-color);
  font-weight: 500;
  font-size:25px;
  padding: 2rem 0 0 0;
  text-align: center;
}

.subTitle {
  color: var(--font-color);
  font-weight: 500;
  font-size: 25px;
}

.content{
  @extend .subTitle;
  font-size: 16px;
  text-align: center;
  padding: 0 4rem 0 4rem
}

.contentTitle{
  @extend .mainTitle;
  font-size: 16px;
  text-align: center;
}

.buttonIndicator{
  border-radius: 50%;
  height: 12px;
  border: none;
  width: 10px;
  transition: all 250ms;
  cursor: pointer;
}

.buttonIndicatorActive{
  border-radius: 11px;
  width: 45px;
}

.buttonIndicator:nth-child(2){
  margin: 0 10px;
}

.main{
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  gap: 2rem;
}

@media only screen and (max-width: 768px) {
  .imgs{
    display: none;
  }

  .content{
    padding: 0 1rem;
  }

  .contentTitle{
    padding: 0;
  }
}
