.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin: 1rem 1.5rem;
}

.formActionContainer {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.pageTabContent {
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px
}

.pageTabContent:first-child {
  border-bottom-left-radius: 4px;
}

.pageTabContainer {
  display: flex;
  max-width: 100%;
  text-align: center;
  overflow-x: auto;
}

.pageTabContainerActive {
  font-weight: 600;
}

.backContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.isImageHolder {
  width: 1rem;
  height: 1rem;
}

.actionMenuBg {
  display: none;
}

.mobileActionPanel {
  display: block;
}

.actionMenuBtn {
  display: none;
}

.headerTab {
  max-width: 50%;
}

.headerTabTwo {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .mobileActionPanel {
    display: none;
  }

  .headerTabTwo {
    width: 80%;
  }

  .actionMenuBg {
    display: block;
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    z-index: -1;
  }

  .actionMenuBgActive {
    z-index: 999;
  }

  .actionMenuBtn {
    display: block;
    padding-right: 1rem;
  }

  .headerContainer {
    align-items: center;
  }

  .mobileFilterContainer {
    box-shadow: var(--box-shadow);
    border: var(--border);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--primary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 20rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
    z-index: 9;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .headerTab {
    width: 80%;
    overflow: auto;
  }

  .formActionContainer {
    display: none;
  }
}
