.mainContainer {
  display: flex;
  background: #ffff;
  position: relative;
  width: 100%;
  min-height: 100dvh;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: clamp(2rem, 7vw - 1rem, 10rem);
  gap: 1rem;
  position: relative;
  margin: auto 0;
}

.signUpFont {
  color: #707070;
}

.loginBtn {
  padding: 1.25rem 1rem;
  background: #3182C1;
  border-radius: var(--border-radius);
  font-size: 14px;
  width: 100%;
  color: #ffff;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.signInTextFive {
  color: #000000;
  font-size: 16px;
  font-family: 'Poppins';
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.loginTextContainer {
  text-align: center;
  display: flex;
}

.signBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.formsTitle {
  color: var(--secondary-color) !important;
  font-weight: bold;
}

.signInTextFour {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .rightContainer {
    width: 100%;
    text-align: center;
  }

  .mainContainer {
    flex-direction: column;
    background: var(--background-color);
  }
}
