.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    padding-right: 1.2rem;
    box-sizing: border-box;
    background: var(--background-color);
    gap: 1rem;
    padding-top: 0;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}

.actionContainer {
    display: flex;
    gap: 1rem;
    overflow: unset;
}

.isImageHolder {
    width: 1rem;
    height: 1rem;
}