.cardMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
    border: var(--border);
    border-radius: var(--border-radius);
    background: var(--highlight-color);
}