/*
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
    --arrow-size: 5px;
    position: relative;
    z-index: 9;
  }

  [data-tooltip]:hover{
    z-index: 100;
  }
  /* Positioning and visibility settings of the tooltip */
  [data-tooltip]:before,
  [data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + var(--arrow-size));
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
  }

  /* The actual tooltip with a dynamic width */
  [data-tooltip]:before {
    content: attr(data-tooltip);
    padding: 5px 5px;
    min-width: 50px;
    max-width: 100px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    background-image: linear-gradient(30deg,
      rgba(59, 72, 80, 0.44),
      rgba(59, 68, 75, 0.44),
      rgba(60, 82, 88, 0.44));
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%,  calc(0px - var(--arrow-size))) scale(0.5);
  }

  /* Tooltip arrow */
  [data-tooltip]:after {
    content: '';
    border-style: solid;
    border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size); /* CSS triangle */
    border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
    transition-duration: 0s; /* If the mouse leaves the element,
                                the transition effects for the
                                tooltip arrow are "turned off" */
    transform-origin: top;   /* Orientation setting for the
                                slide-down effect */
    transform: translateX(-50%) scaleY(0);
  }

  /* Tooltip becomes visible at hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
  /* Scales from 0.5 to 1 -> grow effect */
  [data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
  }
  /*
    Arrow slide down effect only on mouseenter (NOT on mouseleave)
  */
  [data-tooltip]:hover:after {
    transition-delay: 0.5s; /* Starting after the grow effect */
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
  }
  /*
    That's it for the basic tooltip.

    If you want some adjustability
    here are some orientation settings you can use:
  */

  /* LEFT */
  /* Tooltip + arrow */
  [data-tooltip-location="left"]:before,
  [data-tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + var(--arrow-size));
    bottom: 50%;
  }

  /* Tooltip */
  [data-tooltip-location="left"]:before {
    transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
  }
  [data-tooltip-location="left"]:hover:before {
    transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
  }

  /* Arrow */
  [data-tooltip-location="left"]:after {
    border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.9);
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
  }
  [data-tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1);
  }



  /* RIGHT */
  [data-tooltip-location="right"]:before,
  [data-tooltip-location="right"]:after {
    left: calc(100% + var(--arrow-size));
    bottom: 50%;
  }

  [data-tooltip-location="right"]:before {
    transform: translate(var(--arrow-size), 50%) scale(0.5);
  }
  [data-tooltip-location="right"]:hover:before {
    transform: translate(var(--arrow-size), 50%) scale(1);
  }

  [data-tooltip-location="right"]:after {
    border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0px;
    border-color: transparent rgba(0, 0, 0, 0.9) transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
  }
  [data-tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1);
  }



  /* BOTTOM */
  [data-tooltip-location="bottom"]:before,
  [data-tooltip-location="bottom"]:after {
    top: calc(100% + var(--arrow-size));
    bottom: auto;
  }

  [data-tooltip-location="bottom"]:before {
    transform: translate(-50%, var(--arrow-size)) scale(0.5);
  }
  [data-tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, var(--arrow-size)) scale(1);
  }

  [data-tooltip-location="bottom"]:after {
    border-width: 0px var(--arrow-size) var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
    transform-origin: bottom;
  }

  //top
  [data-tooltip-location="top"]:before,
  [data-tooltip-location="top"]:after {
    bottom: calc(100% + var(--arrow-size));
    // top: auto;
  }

  [data-tooltip-location="top"]:before {
    transform: translate(-50%,var(--arrow-size)) scale(0.5);
  }
  [data-tooltip-location="top"]:hover:before {
    // transform: translate(-50%,var(--arrow-size)) scale(1);
  }

  [data-tooltip-location="top"]:after {
    border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size);
    border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
    transform-origin: top;
  }

  @media (max-height: 450px) {
    main {
      margin: 2rem 0;
    }
  }

  @media (max-width: 800px) {
    html {
      font-size: 0.9em;
    }
  }

  /* Thumbnail settings */
  @media (max-width: 750px) {

    [data-tooltip]:after {
      bottom: calc(100% + 3px);
    }
    [data-tooltip]:after {
      border-width: 7px 7px 0px 7px;
    }
  }
