.container {
  position: relative;
  display: flex;
  height: 1rem;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background: #F8F8F8;
  border: 1px solid #c2c2c2;
  border-radius: var(--border-radius);
}

.container:hover input~.checkmark {
  border: 1px solid #c2c2c2;
  box-shadow: var(--box-shadow);
}

.container input:checked~.checkmark {
  background-color: #ffff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  margin: auto;
  width: 9px;
  height: 9px;
  left: 20%;
  top: 20%;
  background-color: var(--secondary-color);
}

.labelText {
  margin-left: 1.5rem;
  font-size: 0.75rem;
}

.isNormalLabel {
  font-size: 1rem;
}

.isDisabled {
  pointer-events: none;
}
