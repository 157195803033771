.container {
  border: var(--border);
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
}

.titleContainer {
  padding-bottom: 1rem;
}

.flexContainer {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
}

.planItem {
  border: var(--border);
  border-radius: 4px;
  padding: 1rem;
  width: 25%;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 5px #d5d5d5;
  }
}

@media screen and (max-width:750px) {
  .flexContainer {
    flex-direction: column;
  }

  .planItem {
    width: unset;
  }
}

.activePlanItem {
  border: var(--border);
  background-color: var(--accent-color);
}

.splitter {
  height: 1px;
  width: 100%;
  background-color: #d5d5d5;
  margin: 0.5rem 0;
}

.error {
  text-align: center;
  font-size: 12px;
  margin-top: 1rem;
  color: #ff0000;
}

.containerErr {
  border-color: #ff0000;
}

.containerDisabled {
  pointer-events: none;
  background-color: #f4f3f3;
}

.alignCentre {
  text-align: center;
}

.boldFont {
  font-weight: bold;
}

.capitalize {
  &::first-letter {
    text-transform: uppercase;
  }
}
