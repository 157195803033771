.main {
  padding: 1rem 1.5rem;
  padding-right: 1.2rem;
  box-sizing: border-box;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
}

.cardContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.loaderContainer {
  position: fixed;
  margin-left: 50%;
  top: 50%;
  //background: rgba(0,0,0,0.5);
  z-index: 999;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #000;
}



.loaderClose {
  opacity: 0;
  z-index: -1;
}

.contentPane {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  &.contentReady {
    opacity: 1;
  }
}

.card {
  background: var(--highlight-color);
  border: none;
  border-radius: var(--border-radius);
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: all 500ms;
  gap: 0.5rem;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.welcomeContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.charts {
  border: none;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: var(--highlight-color);
  width: 24%;
  box-sizing: border-box;
}

.chartContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.noFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--accent-color);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius);
  flex-grow: 1;
  gap: 1rem;
}

.chartHeight {
  height: 300px;

  &+div+div {
    position: unset !important;
  }
}

.barChart {
  width: 25%;
}

.pieChart {
  width: 25%;
}

.geoChart {
  width: 50%;
  flex-grow: 1;
  position: relative;
}

.geoText {
  position: absolute;
  top: 50%;
  left: 30%;
  z-index: 9;
}

.geoMap {
  height: 300px;
  opacity: 0.3;
}

.geoTitle {
  opacity: 0.3;
  padding: 1rem;
}

.chartTitle {
  padding: 1rem;
}

.cardFont {
  font-weight: 600;
  color: rgb(61, 61, 61);
  text-align: center;
}

.bannerMain {
  padding: 1rem 1.5rem;
  padding-bottom: 0;
  background: rgb(248, 248, 248);
}

.freeBanner {
  width: 100%;
  text-align: center;
  background-color: var(--primary-color);
  padding: 0.75rem;
  border: var(--border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  display: flex;
  box-shadow: var(--box-shadow);
  align-items: center;
  gap: 1rem;

  img {
    margin-left: auto;
    cursor: pointer;

    &:active {
      scale: (0.9);
    }
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.loadingEnd {
  animation: fade-out 1s linear;
}


.innerBanner {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}


@media only screen and (max-width: 768px) {
  .topContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .main {
    padding: 1rem 1.5rem;
    padding-top: 0;
  }

  .cardContainer {
    min-height: 6rem;
    gap: 1rem;
    padding: 1rem 0;
    padding-top: 0;
    overflow-x: scroll;
  }

  .welcomeContainer {
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
  }

  .innerBanner {
    flex-direction: column;
    align-items: unset;
  }

  .card {
    padding: 0.5rem 3rem;
    width: 100%;
  }

  .cardText {
    width: max-content;
  }

  .chartContainer {
    flex-direction: column;
  }

  .charts {
    width: 100%;
  }

  .geoText {
    left: 22%;
  }

  .btnCont {
    display: none;
  }
}

@media only screen and (min-width : 768px) and (max-width: 1024px) {
  .pieChart {
    width: 100%;
    flex-grow: 1;
  }

  .geoChart {
    width: 100%;
  }

  .chartContainer {
    flex-wrap: wrap;
  }

  .barChart {
    width: 100%;
  }
}