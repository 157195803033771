.loader {
    width: 1.5rem;
    aspect-ratio: 2;
    // --_g: no-repeat radial-gradient(circle closest-side, var(--primary-color) 90%, #0000);
    --_g: no-repeat linear-gradient(var(--primary-color), var(--primary-color));
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 22% 76%;
    animation: d3 1s infinite linear;
    height: 7px;
}

@keyframes d3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}