.publishContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.fieldContainerShare {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: var(--border);
  margin-top: 10px;
  margin-bottom: 10px;
}

.fieldContainerEmbed {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: var(--border);
  margin-top: 10px;
}


.infoText {
  color: var(--secondary-color);
  font-size: 14px;
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
}

.disabledLink {
  pointer-events: none;
  cursor: not-allowed;
  background: lightgray;
  color: var(--font-color) !important;
  width: fit-content;
  padding: 9px 7px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  opacity: 0.5;
}

.publishBtn {
  width: fit-content;
  padding: 9px 7px;
  box-sizing: border-box;
  background: var(--secondary-color);
  color: #fff !important;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.copyInputContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.saveBtnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}


.anyoneWithLinkContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.shareEmailContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

}
