.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.5rem;
  padding-right: 1.2rem;
  box-sizing: border-box;
  overflow-y: scroll;
}

.headingContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #d5d5d5;
}

.headerBtn {
  min-width: 12rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
}

.bottomSubContainer {
  margin-left: auto;
  padding-right: 1rem;
  position: fixed;
  bottom: -1rem;
  left: 5rem;
  right: -1rem;
}

@media only screen and (max-width: 768px) {
  .headerBtn {
    min-width: 10rem;
    height: 100%;
  }
}
