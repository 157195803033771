#preload {
  background-image: url(../../../../assets/REPLACE-THESE/personal-user.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
  background-image: url(../../../../assets/REPLACE-THESE/organization-user.svg); // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
}

.mainContainer {
  display: flex;
  background: #ffff;
  height: 100vh;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: clamp(3rem, 7vw - 1rem, 10rem);
  margin: auto;
  gap: 2rem;
}

.signupBtn {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.pageLayout {
  display: contents;
}

.linkColor{
  color: var(--secondary-color) !important;
}

@media only screen and (max-width: 768px) {
  .pageLayout {
    display: none;
  }

  .mainContainer {
    background: var(--background-color);
  }
}

@media(max-width:800px) {
  .mainContainer {
    flex-direction: column;
  }

  .rightContainer {
    width: 100%;
    // padding: 2rem;
    gap: 1.5rem;
  }
}
