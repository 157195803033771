.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #e7e7e7;
  height: 4rem;
  color: var(--primary-color);
  padding: 0 1.5rem;
  box-sizing: border-box;
  z-index: 999;
}

.topContainer {
  display: flex;
  flex-direction: column;
}


.titleContent {
  color: #fff;
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.feedBackIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.orgTitle {
  padding: 0.25rem 0.5rem;
  background-color: var(--secondary-color);
  border-radius: 4px;
  border: 1px solid #ffffff80;
  max-width: calc(100vw / 10);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profileMenu {
  display: none;
}

.activeProfile {
  position: relative;

  .profileMenu {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    position: absolute;
    background: white;
    padding: 0.5rem 0;
    border-radius: var(--border-radius);
    top: 54px;
    right: -3px;
    box-sizing: border-box;
    border: var(--border);
    box-shadow: var(--box-shadow);
    white-space: nowrap;
    font-size: 14px;


    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -19px;
      right: 12px;
      margin: auto;
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent #d5d5d5 transparent;
    }

    * {
      padding: 5px 1rem;
      color: black;

      &:hover {
        cursor: pointer;
        color: black;
      }
    }
  }
}

.profileCircle {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  border-radius: 50%;
  border: 3px solid var(--accent-color);
  cursor: pointer;
}

.profileCircleActive {
  border: 3px solid var(--secondary-color);
}

.menuIcons {
  padding: 0.2rem 0;
  width: 1.5rem;
  height: 1.5rem;
}

.menuItems {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 500ms;
  display: flex;
  justify-content: center;
  transition: all 200ms;

  &:hover {
    transform: scale(1.1);
  }
}

.activeMenuItems {
  background: var(--secondary-color);
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 0.3rem;
}


.line {
  height: 1px;
  background: #828282a9;
  position: relative;
  padding: 0 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.raffleClosebtn {
  background: var(--accent-color);
  border-radius: 48px;
  border: none;
  padding: 0.2rem;
  display: flex;
  box-shadow: none;
  cursor: pointer;
  align-items: center;

  img {
    width: 1.2rem;
  }
}

.headerContainer {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  gap: 1rem;
}

.endRaffleHeader {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .topContainer {
    display: none;
  }
}
