.placeHolder {
    width: 75%;
    color: #4A4A4A;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    pointer-events: none;
    user-select: none;


    img {
        max-width: 30rem;
        margin: auto;
        width: 100%;
    }

    p {
        padding: 0 15%;
    }
}