.container {
  width: 20%;
  min-width: 18rem;
  box-shadow: var(--box-shadow);
  border: none;
  background: var(--secondary-top-bar-color);
  padding: 1rem;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
}

.controlsContainer {
  height: fit-content;
  gap: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  width: calc(100% + 16px);
  padding-right: 11px;

  img{
    pointer-events: none;
  }
}

.controlsBox {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.controls {
  width: calc(50% - 0.5rem);
  background: var(--highlight-color);
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  margin: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.25rem;
  border-radius: var(--border-radius);
  border: var(--border);
  cursor: move;

  img {
    width: 2rem;
    height: 1.25rem;
  }

  p {
    font-size: 0.75rem;
    color: #181228;
  }
}

.controlsContainer::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.controlsContainer::-webkit-scrollbar-track {
  background: transparent;
}

.controlsContainer::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: gray;
}

.mb1 {
  margin-bottom: 1rem;
  color: #2F3454CC;
  font-weight: 600;
}

.mb2 {
  margin-bottom: 1rem;
  color: var(--secondary-color);
  font-weight: 600;
}

.searchInput {
  width: 100%;
}

.createNode {
  background: white;
  width: 100% !important;
  height: 7.25rem;
}

.createNode * {
  opacity: 0;
}
