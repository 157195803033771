.raffleBtnContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.endRaffleHeader {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.raffleClosebtn {
  background: var(--accent-color);
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  outline: none;
  border: none;
  cursor: pointer;

  img{
    width: 1rem;
  }
}

.successToast {
  z-index: 999999;
  position: relative;
}

.closImg {
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;

  &:active {
    transform: scale(0.9);
  }
}

.headerContainer {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
}

.endRaffleContent {
  word-wrap: break-word;
  display: flex;
  width: 80%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.checkTooltip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: black;
    background: white;
    font-size: 0.65rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 0.25rem 0.5rem;
    border: var(--border);
    margin-right: 0.5rem;
    top: 0;
    transform: translateX(0) translateY(-120%);
    white-space: pre;
  }
}

.checkTooltip:hover.toolTipRight {
  &::before {
    right: 0 !important;
  }
}

.modalContainer {
  min-width: 30rem;
  // max-width: 30rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media screen and (max-width:768px) {
  .modalContainer {
    min-width: 16rem;
    max-width: 20rem;
    max-height: calc(100vh - 20rem);
    overflow-y: auto;
  }
}


.main {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  flex-grow: 1;
  gap: 1rem;
}

.userTableMainDiv {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: auto;
}

.userTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: var(--border);
}


.tableRow {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  transition: all 500ms;
}

// .tableRow:hover {
//   cursor: pointer;
//   background: var(--highlight-color);
// }

.headRow {
  background: var(--highlight-color);
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  border: var(--border);
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 1rem;
  cursor: default;
}

.tableRow:last-child {
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
}

.tableData {
  display: flex;
  font-size: 14px;
  width: 30%;
  justify-content: center;
  min-width: 10rem;
}

.cardHolder {
  display: none;
}

.mobileCard {
  display: none;
}

@media only screen and (max-width: 768px) {

  .userTable {
    display: none;
  }

  .mobileCard {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: var(--border);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    padding: 1rem;
    gap: .5rem;
  }

  .rowOne {
    display: flex;
    gap: 0.25rem;
    font-size: 12px;
  }

  .line {
    width: 100%;
    border: 0;
    position: relative;
    bottom: 0;
    height: 1px;
    background: #828282;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rowFour {
    display: flex;
    align-items: center;
  }

  .diamond {
    width: 4px;
    height: 4px;
    background: #828282;
    transform: rotate(45deg);
  }

  .cardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .raffleWinner {
    width: 5rem;
  }
}

.raffleWinner {
  width: 10rem;
}

@media only screen and (max-width: 768px) {
  .raffleCard {
    width: 100%;
  }
}

.raffleCardData {
  display: flex;
  gap: 0.25rem;
}

.tableScroll {
  max-height: 12rem;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  width: 100%;
  padding: 0 4px 2px 15px;
}

.tableScroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
}

.tableScroll::-webkit-scrollbar-thumb {
  border-radius: 0;
  cursor: pointer;
}

.tableScroll::-webkit-scrollbar {
  position: relative;
  left: 2rem;
  cursor: pointer;
}

.policyTheme {
  color: var(#0000ff);
  text-decoration: underline;
  font-weight: bold;
  font-size: 12px;
  margin-top: 1rem;
}

.policyThemeContainer {
  font-size: 12px;
  margin-top: 1rem;
  text-align: center;
}

.infoContainer {
  display: flex;
  gap: 4%;

  & > div {
    box-sizing: border-box;
    border: 6px solid #8cc1ea;
    padding: 1rem;
    width: 48%;
    border-radius: 5px;
    text-align: center;
  }
}

.spinBtn {
  box-sizing: border-box;
  width: 8rem;
  border-radius: 50%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0a60a1;
  font-size: 2.25rem;
  color: white;
  cursor: pointer;
  margin: auto;
  user-select: none;
  box-shadow: 0px 3px 1px 3px rgba(178, 174, 174, 0.75), 0px -4px 4px 0px rgba(233, 233, 233, 1);
  transition: box-shadow linear 100ms;

  &:hover {
    background: #0a60b1;
  }

  &:active{
    scale: 0.9;
    box-shadow: none;
  }
}
