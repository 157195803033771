.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  background: #FFFFFF;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.loaderCube {
  position: absolute;
  left: 50%;
  top: 5rem;
  z-index: 99999;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 300ms ease-in-out, z-index 300ms;

  &.loaderClose {
    opacity: 0;
    z-index: -1;
  }
}

.contentPane {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  transition-delay: 150ms;

  &.contentReady {
    opacity: 1;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--secondary-top-bar-color);
  border-radius: var(--border-radius);
}
