@import "./variables";

.modal {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 78, 93, 0.4588235294);
}

.modal>.modal-container {
  background: #F6F7FC 0% 0% no-repeat padding-box;
  border-radius: var(--border-radius);
  min-width: 30rem;
  text-align: center;
  // width: 50%;
  min-height: 8rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal .heading {
  font-size: 1.5rem;
  font-family: $primary-font;
  color: $primary-font-color;
}

button:disabled {
  background-color: #EEEEEE;
  color: #B1B1B1;
  border-color: transparent;
  cursor: not-allowed;
  box-shadow: none;
}

button:disabled:hover {
  box-shadow: none;
}

textarea {
  font-family: $primary-font;
}
