.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  padding-top: 0;
  min-height: calc(100vh - 9rem);
  box-sizing: border-box;
  background: var(--background-color);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.backBtnContainer {
  align-self: flex-start;
}

.templatesContainer {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
}

.line {
  background: #AFAFAF;
  margin: 0;
  width: 100%;
  height: 0.5px;
  margin-top: 0.65rem;
}

.filterContainer {
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border: var(--border);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  max-width: 16rem;
  min-width: 16rem;
}

.templates {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.mobileFilterContainer {
  display: none;
}

.templateContainer {
  display: flex;
  flex-direction: column;
  width: 22%; //temp
  box-shadow: var(--box-shadow);
  height: fit-content;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
}

.card {
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: all 250ms;
  text-align: center;
  gap: 1rem;
  width: 100%;
}

.card:hover {
  background: var(--highlight-color);
  cursor: pointer;
}

.cardImage {
  background: var(--highlight-color);
  padding: 1rem 1.2rem;
  border-radius: 50%;
}

.templateContainer:hover {
  box-shadow: var(--box-shadow);
}

.templateCard {
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: var(--box-shadow);
  padding: 1.5rem 1rem;
  box-sizing: border-box;
}


.selectedTag {
  height: 2rem;
  background: var(--secondary-color);
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: var(--border-radius);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.searchContainer {
  width: 100%;
}

.templatesMain {
  flex-grow: 1;
  padding: 1rem;
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.tag {
  height: 2rem;
  border: none;
  color:var(--font-color);
  background: var(--secondary-top-bar-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  transition: all 250ms;
  cursor: pointer;
}

.tag:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.filterHolder {
  float: left;
  margin: 3%;
  flex-wrap: wrap;
}

.applyBtn {
  display: none;
}

.mobileCloseBtn {
  display: none;
}

.mobileContent {
  display: none;
}

.mobileHeading {
  display: none;
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .cardFont {
    font-size: 12px;
  }

  .filterContainer {
    width: 100%;
  }

  .card {
    width: auto;
  }

  .templates {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .cardFont {
    font-size: 14px;
  }


  .filterContainer {
    width: 30%;
  }
}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .filterContainer {
    width: 30%;
  }
}

@media only screen and (max-width: 768px) {
  .templateCard {
    padding: 1rem 0;
  }

  .templates {
    grid-template-columns: repeat(2, 1fr);
  }

  .filterContainer {
    display: none;
  }

  .mobileFilterContainer {
    border: none;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    background: var(--secondary-color);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    height: 20rem;
    transform: translate(0%, 100%);
    transition: transform .5s;
  }

  .mobileFilterContainerActive {
    transform: translate(0%, 0%);
    transition: transform .5s;
  }

  .backBtnContainer {
    align-self: unset;
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .isImageHolder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closebtn {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .tagTitle {
    color: #fff;
  }

  .tagTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobileCloseBtn {
    display: contents;
  }

  .card {
    padding: 1rem;
    border-radius: var(--border-radius);
  }

  .mobileTemplateTitle {
    font-size: 14px;
    font-weight: 500 !important;
    color: #000;
  }

  .mobileContent {
    display: contents;
  }

  .mobileHeading {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 0.5rem;
  }

  .headerContainer {
    display: none;
  }

  .mainContainer {
    gap: 1rem;
  }

  .applyBtn {
    display: block;
    align-self: flex-end;
  }

  .actionMenuBg {
    width: 100%;
    min-height: 100vh;
    background: #00000096;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
  }

  .actionMenuBgActive {
    display: block;
    z-index: 99;
  }

  .mobileHeader {
    display: flex;
    align-items: flex-start;
  }
}

.placeholderTemplate {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .mobileFilterContainer {
    height: 10rem;
    left: 0%;
    overflow-y: auto;
  }
}
