.sidepaneMain {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closebtn {
  background: #ffffff;
  border-radius: 48px;
  border: none;
  padding: 0.2rem;
  display: flex;
  box-shadow: none;
  cursor: pointer;
}

.inpuContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.infoText {
  color: var(--secondary-color);
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins";
}

// invite users

//page four
.inviteUserFont {
  color: #707070;
}

.inviteUserTextOne {
  @extend .inviteUserFont;
  font-size: 25px;
  font-weight: bold;
}

.roleSelector {
  margin-top: 10px;
}

.shareEmailContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.mailContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inviteUserTextTwo {
  @extend .inviteUserFont;
  font-size: 1rem;
}

.fieldContainer {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  outline: 2px solid #000000;
}

.continueBtn {
  padding: 1.25rem 1rem;
  background: #3182C1;
  border-radius: var(--border-radius);
  font-size: 14px;
  width: 100%;
  color: #ffff;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center
}

.skipBtn {
  @extend .continueBtn;
  background: #ffff;
  color: #3182C1;
  outline: 1px solid #3182C1;
}

.button {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  height: 35px;
  //border-radius: 3px;
  border: 0;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.copyButton {
  width: 5rem;
  background: #2F3454;
  color: white;
  height: 53px;
}

.shareButton {
  @extend .button;
  @extend .copyButton;
  background: #ffff;
  width: 3rem;
}

.formField {
  width: 100%;
  outline: none;
  border: none;
  padding: 1rem 1rem;
  font-size: 1rem;
  box-sizing: border-box;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

}

// private links css

.copyFieldContainer {
  display: flex;
  position: relative;
  width: calc(100% - 2px);
  outline: 2px solid #000000;
  border-radius: var(--border-radius);
}

.addMailButton {
  width: 5rem;
  right: 1px;
  background: #2F3454;
  padding: 1rem 1rem;
  color: white;
  height: 51px;
  border: var(--border);
}

.privateLinkContainer {
  text-align: left;
  margin-bottom: 20px;
  padding: 1rem;
  background: #ECF5FF;
  border-radius: var(--border-radius);
}

.linkContainer {
  max-width: 40rem;
  min-width: 40rem;
}

.privateLinkInput {
  border: var(--border);
}

.privateEmailsInput {
  background-color: white;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem 1rem;
}

.privateEmailsContainer {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 1rem;
  margin-top: 1rem;
  max-height: 10rem;
  overflow-y: auto;
  padding: 0.5rem 0;
}

.emailsBubble {
  background: var(--primary-color);
  color: white;
  padding: 0.5rem;
  border-radius: var(--border-radius);
}

.errorEmail {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 10px;
  color: #f008088d;
}

.closeEmailBubble {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.emailsBubble {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.copyInputContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.mobileCloseBtn {
  display: none;
}

.saveBtn {
  display: block;
}

@media only screen and (max-width: 768px) {
  .sidepaneMain {
    padding: 2rem;
    background: var(--secondary-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    max-height: 26rem;
    overflow-y: auto;
  }

  .topContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #f8f8f8;
    position: relative;
  }

  .textColor {
    color: #ffffff;
  }

  .infoText {
    color: #fff;
  }

  .mobileCloseBtn {
    display: block;
  }

  .closebtn {
    outline: none;
    border: none;
    cursor: pointer;
    padding: none !important;
  }

  .closeBtnContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .saveBtn {
    display: none;
  }
}
