.userTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    border: var(--border);
}

.userMainContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    padding-top: 0;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 1.2rem;
}

.tableRow {
    display: flex;
    gap: 2rem;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border: var(--border);
    border-left: 0;
    border-right: 0;
    transition: all 500ms;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-sizing: border-box;
    width: 80%
}

.headerContainer {
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    background: var(--secondary-top-bar-color);
    align-items: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.actionContainer {
    display: flex;
    gap: 1rem;
    overflow: unset;
}

.isImageHolder {
    width: 1rem;
    height: 1rem;
}

.loaderContainer {
    position: absolute;
    left: 50%;
    margin-left: 6rem;
    top: 50%;
    z-index: 99999;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
}

.loaderCube {
    position: absolute;
    left: 50%;
    top: 5rem;
    z-index: 99999;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 300ms ease-in-out, z-index 300ms;
}

.loaderClose {
    opacity: 0;
    z-index: -1;
}

.contentPane {
    opacity: 0;
    transition: opacity 350ms ease-in-out;
    transition-delay: 150ms;

    &.contentReady {
        opacity: 1;
    }
}

.mobileCardContainer {
    display: none;

}

.addBtnContainer {
    display: flex;
    gap: 1rem;
}

.saveBtn {
    display: block;
}

.actionPaneMobile {
    display: block;
}

.actionMenuBg {
    display: none;
}

@media only screen and (max-width: 768px) {

    .userTable {
        display: none;
    }

    .mobileCardContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    .actionPaneMobile {
        display: none;
    }

    .userMainContainer {
        padding-bottom: 7rem;
    }

    .mobileCard {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: var(--border);
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);
        box-sizing: border-box;
        padding: 1rem;
        gap: .5rem;
    }

    .rowOne {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
    }

    .line {
        width: 100%;
        height: 1px;
        background: #828282a9;
        position: relative;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .rowFour {
        display: flex;
        align-items: center;
    }

    .addBtnContainer {
        position: fixed;
        bottom: 1rem;
        left: 1.5rem;
        right: 1.5rem;
        z-index: 9;
        background: var(--highlight-color);
        box-shadow: var(--box-shadow);
        padding: 1rem;
    }

    .mobileActionPanel {
        display: block;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 9;
        max-height: 35rem;
        transform: translateY(40rem);
        transition: all .5s;
    }

    .mobileActionPanelActive {
        transform: translateY(0%);
        transition: all .5s;
    }

    .saveBtn {
        display: none;
    }

    .actionMenuBg {
        display: block;
        width: 100%;
        min-height: 100dvh;
        background: #00000096;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
    }

    .actionMenuBgActive {
        z-index: 99;
        opacity: 1;
    }
}