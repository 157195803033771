.fieldContainer {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  border: var(--border);
}

.copyInputContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.linkLabel{
  color: #000;
}

@media only screen and (max-width: 768px) {
  .linkLabel{
    color: #fff;
  }
}
