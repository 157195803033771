.backBtnContainer {
  align-self: unset;
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #eeeeee;
  padding: 0.5rem;
  width: 100%;
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.titleText {
  font-size: 16px !important;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
}

.tabsLayout {
  width: 25%;
}

.tabStyles {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .tabStyles {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .tabsLayout {
    width: 100%;
  }
}
