.menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100dvh;
    // background: var(--primary-color);
    transition: left .5s;
}

.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    // opacity: 0;
    backdrop-filter: brightness(0.5) blur(3px);
    transition: opacity .6s;
    z-index: unset;
}

.menuActive {
    left: 0;
}

.mainActive{
  opacity: 1;
}

.hamburgerBtn {
    position: relative;
    width: 50px;
    height: 45px;
}


.hamBar {
    width: 65%;
    height: 5px;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .6s, opacity .8s, top .6s, background .6s;
    border-radius: var(--border-radius);
}

.one {
    width: 35%;
    top: 25%;
    left: 30%;
}

.two {
    left: 45%;

}

.three {
    top: 75%;
    width: 50%;
    left: 36%;
}

.oneActive {
    width: 70%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%) rotate(-315deg);
    top: 50%;
}

.twoActive {
    opacity: 0;
}

.threeActive {
    width: 70%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%) rotate(-225deg);
    top: 50%;
}
