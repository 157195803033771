// @import 'bulma-calendar/dist/css/bulma-calendar.min.css';

.datetimepicker .datetimepicker-header.is-date-only {
    flex-direction: row;
    display: none;
}


.datetimepicker.is-primary .datepicker-nav {
    background: #f8f8f8;
  }

  .datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-today {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
    border: var(--border);
    border-radius: var(--border-radius);
  }

  .datetimepicker .timepicker .timepicker-end .timepicker-input-number, .datetimepicker .timepicker .timepicker-start .timepicker-input-number {
    font-size: 2rem !important;
  }

  .datetimepicker-dummy .datetimepicker-clear-button{
    top: -4px !important;
  }
  .datetimepicker-dummy .datetimepicker-dummy-wrapper::before {
    top: auto !important;
  }

  .datetimepicker-dummy {
    height: auto !important;
}


  .datetimepicker .datepicker-nav .datepicker-nav-year {
    color: black;
  }

  .datetimepicker .datepicker-nav .datepicker-nav-month {
    color: black;
  }

  .datepicker-nav-previous {
    background: #FFFFFF;
    border: var(--border);
    border-radius: var(--border-radius);
  }

  .datetimepicker .datepicker-nav .datepicker-nav-next {
    background: #FFFFFF;
    border: var(--border);
    border-radius: var(--border-radius) !important;
  }

  .datetimepicker .datepicker-nav .datepicker-nav-previous {
    background: #FFFFFF;
    border: var(--border);
    border-radius: var(--border-radius) !important;
  }

  .datetimepicker .datepicker-nav .datepicker-nav-next svg {
    color: #000000;
  }

  .datetimepicker .datepicker-nav .datepicker-nav-previous svg {
    color: #000000;
  }

  .datetimepicker .datepicker-body {
    background: #f8f8f8;
    font-family: "Poppins";
  }

  .datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-active {
    border-color: var(--secondary-color);
    color: #f8f8f8;
    background: var(--secondary-color);
    border: var(--border);
    border-radius: var(--border-radius);
  }

  .datetimepicker .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item:hover {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
    border: var(--border) !important;
    border-radius: var(--border-radius) !important;
  }

  .datetimepicker-wrapper {
    font-family: "Poppins";
  }

  .datetimepicker .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item {
    font-family: "Poppins";
  }

  .button.is-text {
    font-family: "Poppins";
    color: #000000 !important;
  }

  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper.is-active,
  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper.is-focused,
  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper:active,
  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper:focus {
    border-color: var(--secondary-color);
    box-shadow: none;
  }

  .datetimepicker-dummy.is-primary .datetimepicker-clear-button {
    color: #000000;
  }

  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper::before,
  .datetimepicker-dummy.is-primary .datetimepicker-dummy-wrapper:before,
  .datetimepicker-dummy.is-primary::before,
  .datetimepicker-dummy.is-primary:before {
    background-color: #000000;
  }

  .datetimepicker-dummy .datetimepicker-dummy-wrapper .datetimepicker-dummy-input {
    font-family: "Poppins";
  }

  .datetimepicker .datepicker-body .datepicker-months .datepicker-month.is-active,
  .datetimepicker .datepicker-body .datepicker-months .datepicker-year.is-active,
  .datetimepicker .datepicker-body .datepicker-years .datepicker-month.is-active,
  .datetimepicker .datepicker-body .datepicker-years .datepicker-year.is-active {
    border-color: var(--secondary-color);
    background: var(--secondary-color);
  }

  .datetimepicker .datepicker-body .datepicker-months .datepicker-month:hover,
  .datetimepicker .datepicker-body .datepicker-months .datepicker-year:hover,
  .datetimepicker .datepicker-body .datepicker-years .datepicker-month:hover,
  .datetimepicker .datepicker-body .datepicker-years .datepicker-year:hover {
    border: var(--border);
  }

  .datetimepicker.is-primary .timepicker-input,
  .datetimepicker.is-primary .timepicker-input-number,
  .datetimepicker.is-primary .timepicker-time-divider {
    color: var(--secondary-color) !important;
  }

  .datetimepicker-dummy .datetimepicker-dummy-wrapper {
    box-shadow: none;
    border: var(--border);
    min-width: 0;
  }

  .datetimepicker-dummy .datetimepicker-dummy-wrapper.is-hovered, .datetimepicker-dummy .datetimepicker-dummy-wrapper:hover {
    border: var(--border);
  }
