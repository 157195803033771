.file {
  display: none;
}

.container {
  display: flex;
  border-radius: var(--border-radius);
  flex-wrap: wrap;
  gap: 1rem;
}

.fileInput {
  background-color: #abacb3;
  padding: 7px 14px;
  border-radius: var(--border-radius);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: not-allowed;
}

.fileName {
  color: #3182C1;
  cursor: pointer;

  &.loading .isLoading {
    margin-left: 3px;
    display: inline;
  }
}

.isLoading {
  display: none;
  cursor: auto;
  width: 30px;
  fill: #000;
  vertical-align: middle;
}
