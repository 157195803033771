.chartHeight {
  height: 18rem;
}

.analyticsTitle {
  background: #d5d5d5;
  padding: 1.2rem;
}

.analyticsMain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chartHeader{
  background:#e7e7e7;
  padding:1rem;
}

.container {
  display: flex;
  gap: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

.noFormsContainer {
  background: var(--highlight-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius);
}

.noFormsMainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  margin-top: 5.45rem;
}

.backBtnContainer {
  align-self: unset;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.backImg {
  width: 1rem;
  height: 1rem;
  transform: rotate(180deg);
}

.titleText {
  font-size: 16px !important;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
  min-height: 100vh;
  box-sizing: border-box;
}

.cardContainer {
  justify-content: space-between;
  width: 100%;
  display: grid;
}

.textContainer {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.allResponsesContainer {
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
}

.charts {
  border: none;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  width: 100%;
  box-sizing: border-box;
}

.chartContainer {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.tabContainer {
  display: flex;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
