.container {
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
}

.titleContainer {
  padding-bottom: 1rem;
}

.flexContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.planItem {
  border: var(--border);
  border-radius: 4px;
  padding: 1rem;
  width: 25%;
}

.containerDisabled {
  pointer-events: none;
  background-color: #f4f3f3;
}

.bottomCheckToolTip:hover {
  &::before {
    content: attr(tool-tip);
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.85);
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: var(--border-radius);
    padding: 0.5rem;
    z-index: 99999;
    min-width: 8rem;
    text-align: center;
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 99999;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0, 0, 0, 0.85);
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -10px;
  }
}

.toolTipImg {
  width: 0.8rem;
  height: 0.8rem;
}

.showTable {
  display: flex !important;
}
