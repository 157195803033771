.container {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #F8F8F8;
    border: var(--border);
    box-shadow: var(--box-shadow);
}

.container:hover input~.checkmark {
    border: var(--border);
    box-shadow: var(--box-shadow);
}

.container input:checked~.checkmark {
    background-color: #ffff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

.container .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 15%;
    top: 16%;
    background-color: var(--primary-color);
}