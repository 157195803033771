.btnContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.content {
    display: flex;
    flex-grow: 1;
}

.modalContainer {
    background: #F6F7FC 0% 0% no-repeat padding-box;
    border-radius: 10px;
    min-width: 75vw;
    text-align: center;
    min-height: 80vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 6rem;
    margin-top: 2rem;
    z-index: 999
}


@media only screen and (max-width: 768px) {
    .modalContainer {
        margin-left: 0rem;
    }
}