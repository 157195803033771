.mainContainer {
  display: flex;
  background: #ffff;
  height: 100vh;
  position: relative;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: clamp(2rem, 7vw - 1rem, 10rem);
  margin: auto;
  gap: 1rem;
}

.signUpFont {
  color: #707070;
}

.signUpTextOne {
  @extend .signUpFont;
  font-size: 25px;
  font-weight: bold;
}

.signUpTextTwo {
  @extend .signUpFont;
}

.fieldContainer {
  position: relative;
}

.formLabel {
  @extend .signUpFont;
  position: absolute;
  left: 0.5rem;
  padding: 0 0.5rem;
  top: -0.7rem;
  background: #FFFFFF;
}


.formField {
  width: 100%;
  outline: none;
  border: var(--border);
  padding: 1.25rem 1rem;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  transition: all 0.2s;
  font-size: 1rem;
}

.formFieldError {
  width: 100%;
  outline: none;
  padding: 1.25rem 1rem;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  transition: all 0.2s;
  border: 0.5px solid #f70505;
}

.formField:focus-visible {
  box-shadow: var(--box-shadow);
}

.loginBtn {
  padding: 1.25rem 1rem;
  background: #3182C1;
  border-radius: var(--border-radius);
  font-size: 14px;
  width: 100%;
  color: #ffff;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.signUpTextThree {
  @extend .signUpFont;
  font-size: 18px;
  font-weight: bold;
}

.directLoginContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.directLoginBtn {
  padding: 1.25rem 1rem;
  width: 100%;
  border: var(--border);
  border-radius: var(--border-radius);
  outline: none;
  color: #2F3454;
  font-weight: bold;
  background: #ffff;
  cursor: pointer;
  justify-content: center;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.directLoginBtn:hover {
  border-color: #0089D6;
  color: #0089D6;
}

.signUpTextFour {
  @extend .signUpFont;
  font-size: 16px;
  padding-top: 1.5%;
  padding-left: 5%;
}

.signUpTextFive {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.loginTextContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mobileContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

.mobileCountryCodeContainer {
  width: 40%;
}

.mobileNumberContainer {
  width: 60%;
}

.signupBtn{
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.errorText {
  color: red;
  font-size: 10px;
  margin: 0;
  font-family: "Poppins";
}


.login{
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 7%;
  padding: 6px;
}
.pageLayout{
  display: contents;
}

@media only screen and (max-width: 768px) {
  .rightContainer {
    // padding: 0 1rem;
    width: 100%;
  }

  .mainContainer{
    background: var(--background-color);
   }
  .pageLayout{
    display: none;
  }

  .login{
    width: 20%;
  }
}

/* portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    // padding: 0 1rem;
  }

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    // padding: 0 3rem;
  }
}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .rightContainer {
    // padding: 0 5rem;
  }
}
