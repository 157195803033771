.main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: brightness(0.5) blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 99999;
}

.modalContainer {
  background: #f8f8f8;
  border-radius: 4px;
  border: var(--border);
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .modalContainer {
    max-height: calc(100vh - 20rem);
    overflow: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .modalContainer {
    max-height: calc(100vh - 20rem);
    overflow: auto;
  }
}
