.otpGroup {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  background: #fff;
}

.otpInput {
  text-align: center;
  width: 100%;
  height: 60px;
  border: none;
  border-bottom: var(--border);
  font-size: 32px;
  font-weight: bold;
  box-shadow: var(--box-shadow);
  line-height: 1;
}


@media screen and (max-width : 768px) {
  .otpInput{
    font-size: 24px;
  }
}